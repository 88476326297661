import moment from 'moment-timezone';
import {
  CSVLine,
  CustomerLocationOrder,
  DeliveryOrder,
  ExtendedDeliveryOrder,
  ExtendedPackageGroup,
  Package,
  PackageGroup,
  PackageModel,
} from '../store/config/types/deliveryOrders.types';

// Package statuses: ORDER_SUBMITTED, AWAITING_PICKUP, ON_THE_WAY, FAILED, DELIVERED
// Delivery Order item statuses: ORDER_SUBMITTED, SCHEDULED, IN_TRANSIT, DELIVERED, NEEDS_ATTENTION

export function translateStatus(status: string) {
  switch (status) {
    case 'NEW':
      return 'New';
    case 'ORDER_SUBMITTED':
      return 'Added to Trip';
    case 'AWAITING_PICKUP':
      return 'Scheduled';
    case 'DELIVERED':
      return 'Delivered';
    case 'FAILED':
      return 'Failed';
    case 'FAILED_ON_LOAD':
      return 'Failed on load';
    case 'FAILED_ON_DROP_OFF':
      return 'Failed on drop off';
    case 'MISS_TIME_WINDOW':
      return 'Miss the Time Window';
    case 'NEEDS_ATTENTION':
      return 'Needs Attention';
    case 'MIXED_STATUSES':
      return 'Mixed statuses';
    case 'IN_TRANSIT':
      return 'In Transit';
  }
  return 'Unknown';
}

export function getConclusionOfStatuses(statuses: string[], originalString: boolean = false): string {
  if (statuses.every((status: string) => status === 'ORDER_SUBMITTED'))
    return originalString ? 'ORDER_SUBMITTED' : translateStatus('ORDER_SUBMITTED');
  if (statuses.every((status: string) => status === 'NEW')) return originalString ? 'NEW' : translateStatus('NEW');
  else if (statuses.every((status: string) => status === 'AWAITING_PICKUP'))
    return originalString ? 'AWAITING_PICKUP' : translateStatus('AWAITING_PICKUP');
  else if (statuses.every((status: string) => status === 'DELIVERED'))
    return originalString ? 'DELIVERED' : translateStatus('DELIVERED');
  else if (
    statuses.some((status: string) =>
      ['FAILED', 'FAILED_ON_LOAD', 'FAILED_ON_DROP_OFF', 'MISS_TIME_WINDOW'].includes(status),
    )
  )
    return originalString ? 'NEEDS_ATTENTION' : translateStatus('NEEDS_ATTENTION');
  else return originalString ? 'IN_TRANSIT' : translateStatus('IN_TRANSIT');
}

export function getPackageGroupStatus(packages: Package[], originalString: boolean = false): string {
  const packagesStatuses: string[] = packages.map((packageItem: Package) => packageItem.changeHandStatus);
  return getConclusionOfStatuses(packagesStatuses, originalString);
}
export function isOutsideTheTimeWindow(deliveryOrder: CustomerLocationOrder, timezone: string | null = null) {
  timezone && moment.tz.setDefault(timezone);
  const TZ = moment().format('Z');
  const deliveryDate = moment(deliveryOrder.deliveryExpectedAt).format('YYYY-MM-DD');
  let outsideTheTimeWindow = false;
  if (moment(deliveryOrder.deliveryExpectedAt).startOf('day').isAfter(moment().endOf('day'))) {
    // delivery is in the future
    return false;
  }
  deliveryOrder.packageGroups.forEach((packageGroup) => {
    // const arrivalTime = moment(packageGroup.arrival).format('HH:mm:ss');
    // const departureTime = moment(packageGroup.departure).format('HH:mm:ss');
    if (packageGroup.timeWindowFrom || packageGroup.timeWindowTo) {
      if (
        !moment(packageGroup.arrival).isBetween(
          moment(`${deliveryDate} ${packageGroup.timeWindowFrom ?? '00:00:01'} ${TZ}`, 'YYYY-MM-DD HH:mm:ss Z'),
          moment(`${deliveryDate} ${packageGroup.timeWindowTo ?? '23:59:59'} ${TZ}`, 'YYYY-MM-DD HH:mm:ss Z'),
        )
      ) {
        outsideTheTimeWindow = true;
      }
      if (
        !moment(packageGroup.departure).isBetween(
          moment(`${deliveryDate} ${packageGroup.timeWindowFrom ?? '00:00:01'} ${TZ}`, 'YYYY-MM-DD HH:mm:ss Z'),
          moment(`${deliveryDate} ${packageGroup.timeWindowTo ?? '23:59:59'} ${TZ}`, 'YYYY-MM-DD HH:mm:ss Z'),
        )
      ) {
        outsideTheTimeWindow = true;
      }
    }
  });
  return outsideTheTimeWindow;
}
export function getStatusOfAllPackageGroups(
  deliveryOrder: CustomerLocationOrder,
  originalString: boolean = false,
  timezone: string | null = null,
): string {
  const packageGroupStatuses: string[] = [];
  const missTimeWindow = isOutsideTheTimeWindow(deliveryOrder, timezone);
  deliveryOrder.packageGroups.forEach((packageGroup) => {
    const packagesStatuses: string[] = packageGroup.packages.map((pckg) => pckg.changeHandStatus);
    if (missTimeWindow) {
      packagesStatuses.push('MISS_TIME_WINDOW');
    }
    const status = getConclusionOfStatuses(packagesStatuses, true);
    if (!packageGroupStatuses.includes(status)) packageGroupStatuses.push(status);
  });
  const finalStatus = packageGroupStatuses.length > 1 ? 'MIXED_STATUSES' : packageGroupStatuses[0];
  return originalString ? finalStatus : translateStatus(finalStatus);
}

export function getStatusOfPackageGroupInSingleLine(packageGroup: PackageGroup) {
  const packagesCount = packageGroup.packages?.length ?? 0;
  let singleLineStatus = '';
  if (packagesCount > 0) {
    [
      'NEW',
      'ORDER_SUBMITTED',
      'AWAITING_PICKUP',
      'DELIVERED',
      'FAILED',
      'FAILED_ON_LOAD',
      'FAILED_ON_DROP_OFF',
      'MISS_TIME_WINDOW',
      'NEEDS_ATTENTION',
      'IN_TRANSIT',
    ].forEach((status) => {
      const statusCount = packageGroup.packages.reduce(
        (count, pckg) => count + (pckg.changeHandStatus === status ? 1 : 0),
        0,
      );
      if (statusCount === packagesCount) return 'All is ' + translateStatus(status);
      if (statusCount > 0)
        singleLineStatus += `${singleLineStatus !== '' ? ', ' : ''}${statusCount} ${translateStatus(status)}`;
    });
    if (singleLineStatus.endsWith(', ')) singleLineStatus = singleLineStatus.substring(0, singleLineStatus.length - 2);
    return singleLineStatus;
  }
}

export function extendDeliveryOrder(deliveryOrder: DeliveryOrder): ExtendedDeliveryOrder {
  let packagesAmount: number = 0;

  deliveryOrder.packageGroups.forEach((packageGroup: PackageGroup) => {
    packagesAmount += packageGroup.packages?.length || 0;
  });

  const recipientsCount: number = deliveryOrder.packageGroups.length;

  const extendedDeliveryOrder: ExtendedDeliveryOrder = {
    ...deliveryOrder,
    packageGroups: deliveryOrder.packageGroups.map((packageGroup: PackageGroup) => {
      const extendedPackageGroup: ExtendedPackageGroup = {
        ...packageGroup,
        packageGroupStatus: getPackageGroupStatus(packageGroup.packages),
      };
      return extendedPackageGroup;
    }),
    packagesAmount,
    numberOfRecipients: recipientsCount,
  };

  return extendedDeliveryOrder;
}

export function createPackagesList(deliveryOrder: ExtendedDeliveryOrder, packageGroup?: PackageGroup): PackageModel[] {
  const packages: PackageModel[] = (packageGroup ? [packageGroup] : deliveryOrder.packageGroups).map((packageGroup) => {
    const packageModel: PackageModel = {
      customerId: packageGroup.customerId,
      deliveryInstructions: packageGroup.instructions,
      packageId: packageGroup.packageGroupId,
      packagesAmount: packageGroup.packages.length,
      packagesSize: packageGroup.packages[0].size.charAt(0),
      // packagesStatus: packageGroup.packageGroupStatus,
    };

    return packageModel;
  });

  return packages;
}

export function transformCSVLines(csvLines: CSVLine[] | null): PackageModel[] {
  if (csvLines === null) {
    return [];
  }

  const parsedLines: PackageModel[] = csvLines.map((csvLine: CSVLine) => {
    return {
      ...csvLine,
      customerId: csvLine.storedCustomerId,
      packagesAmount: Number(csvLine.count),
      packagesSize: csvLine.size,
    };
  });
  return parsedLines;
}

export function formatPackagesStatus(packagesStatus: string): string {
  const prettyStatus: string = packagesStatus.toLowerCase().replaceAll('_', ' ');
  return prettyStatus.charAt(0).toLocaleUpperCase() + prettyStatus.slice(1);
}

export const readablePackageStatus = (status: string): string => {
  switch (status) {
    case 'NEW':
      return 'New';
    case 'ORDER_SUBMITTED':
      return 'Added to Trip';
    case 'AWAITING_PICKUP':
      return 'Scheduled';
    case 'FAILED_ON_LOAD':
      return 'Load failed';
    case 'ON_THE_WAY':
      return 'In transit';
    case 'FAILED_ON_DROP_OFF':
      return 'Drop off failed';
    case 'DELIVERED':
      return 'Successfuly delivered';
    case 'FAILED':
      'Failed';
  }
  return 'unknown';
};

export const deliveriesSortFunction = (
  field: string,
  order: string,
  a: CustomerLocationOrder,
  b: CustomerLocationOrder,
) => {
  let res = 0;
  switch (field) {
    case 'date':
      const dateA = a.deliveryExpectedAt ?? 0;
      const dateB = b.deliveryExpectedAt ?? 0;
      res = dateA > dateB ? 1 : dateA == dateB ? 0 : -1;
      break;
    case 'status':
    case 'name':
    case 'companyName':
    case 'address':
    case 'shipVia':
      let strA = a.status ?? '';
      let strB = b.status ?? '';
      if (field == 'name') {
        strA = `${a.customer.firstName} ${a.customer.lastName}`;
        strB = `${b.customer.firstName} ${b.customer.lastName}`;
      } else if (field == 'companyName') {
        strA = a.customer.companyName ?? '';
        strB = b.customer.companyName ?? '';
      } else if (field == 'address') {
        strA = a.shippingLocation?.streetAddress ?? '';
        strB = b.shippingLocation?.streetAddress ?? '';
      } else if (field == 'shipVia') {
        strA = a.shipVia ?? '';
        strB = b.shipVia ?? '';
      }
      strA = strA.trim().toLowerCase();
      strB = strB.trim().toLowerCase();
      res = strA > strB ? 1 : strA == strB ? 0 : -1;
      break;
  }
  return order === 'ASC' ? res : -res;
};

export function getDeliveryOrderSearchString(deliveryOrder: CustomerLocationOrder) {
  return (
    `${deliveryOrder.packageGroups.map((pg) => `${pg.packageGroupId} ${pg.additionalData?.label ?? ''} `).join(' ')}` +
    `${deliveryOrder.customer.firstName} ` +
    `${deliveryOrder.customer.lastName} ` +
    `${deliveryOrder.shippingLocation?.streetAddress} ` +
    `${deliveryOrder.customer.companyName} ` +
    `${deliveryOrder.shipVia == 'DEFAULT' ? 'Curbhub' : deliveryOrder.shipVia} `
  );
}

export function getPackageGroupLabel(packageGroup: PackageGroup) {
  return `${packageGroup.additionalData?.label ?? 'PG#' + packageGroup.packageGroupId}`;
}

export function fixDeliveryOrderFilters(filters: any) {
  const fixedFilter: any = {};
  Object.keys(filters).forEach((filter) => {
    if (filters[filter] !== undefined && filters[filter] !== null && filters[filter] !== '') {
      fixedFilter[filter] = filters[filter];
    }
  });
  return fixedFilter;
}

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { FulfillmentItem, ItemPendingFulfillmentStruct, PackageGroup } from '../../store/config/types';
import { useEffect, useState } from 'react';
import { FulfillmentService } from '../../services/warehouseWork.service';

export interface PackageGroupLotsDialogProps {
  packageGroup?: PackageGroup | undefined;
  itemPendingFulfillment?: ItemPendingFulfillmentStruct;
  onClose: () => void;
}
export default function PackageGroupLotsDialog({
  packageGroup,
  onClose,
  itemPendingFulfillment,
}: PackageGroupLotsDialogProps) {
  const [processing, setProcessing] = useState(false);
  const [fulfillmentItems, setFulfillmentItems] = useState<FulfillmentItem[]>([]);
  const [total, setTotal] = useState(0);
  const [ordered, setOrdered] = useState(0);
  const [addID, setAddID] = useState<string>();
  const [addQuantity, setAddQuantity] = useState(0);

  const handleAdd = () => {
    if (addID && addQuantity) {
      const idx = fulfillmentItems.findIndex((ffi) => ffi.lotId == addID);
      if (idx < 0) {
        const ffItem: FulfillmentItem = {
          fulfilledQuantity: 0,
          fulfillmentItemId: 0,
          lotId: addID,
          quantity: addQuantity,
        };
        setFulfillmentItems([...fulfillmentItems, ffItem]);
      } else {
        editFulfillmentItem(fulfillmentItems[idx].fulfillmentItemId, fulfillmentItems[idx].quantity + addQuantity);
      }
      setAddID('');
    }
  };

  const handleDeleteItem = (fulfillmentItemId: number) => {
    const ffIndex = fulfillmentItems.findIndex((ffi) => ffi.fulfillmentItemId == fulfillmentItemId);
    if (ffIndex >= 0) {
      setFulfillmentItems([
        ...fulfillmentItems.map((ffi, ffidx) => {
          return ffidx != ffIndex ? ffi : { ...ffi, quantity: 0 };
        }),
      ]);
    }
  };
  const editFulfillmentItem = (fulfillmentItemId: number, quantity: number) => {
    const ffIndex = fulfillmentItems.findIndex((ffi) => ffi.fulfillmentItemId == fulfillmentItemId);
    if (quantity < 0) quantity = 0;
    if (ffIndex >= 0 && quantity >= 0) {
      const remaining = ordered - (total - fulfillmentItems[ffIndex].quantity);
      console.log(ordered, total, fulfillmentItems[ffIndex].quantity, remaining);
      if (quantity > remaining) quantity = remaining;
      setFulfillmentItems([
        ...fulfillmentItems.map((ffi, ffidx) => {
          return ffidx != ffIndex ? ffi : { ...ffi, quantity };
        }),
      ]);
    }
  };
  const handleAddQuantityChanged = (val: string) => {
    let value = +val;
    value = Math.min(value, ordered - total);
    if (value > 0) {
      setAddQuantity(value);
    }
  };
  const handleAddIdChanged = (val: string) => {
    setAddID(val);
  };

  useEffect(() => {
    const ffItems: FulfillmentItem[] = [];
    packageGroup?.fulfillment?.warehouseWorkOrders?.forEach((wo) => {
      ffItems.push(...wo.fulfillmentItems);
    });
    setOrdered(ffItems.reduce((totalQuantities, ffi) => totalQuantities + ffi.quantity, 0));
    setFulfillmentItems([...ffItems]);
  }, [packageGroup]);

  useEffect(() => {
    setTotal(fulfillmentItems.reduce((total, ffi) => total + ffi.quantity, 0));
  }, [fulfillmentItems]);

  useEffect(() => {
    setAddQuantity(ordered - total);
  }, [total]);

  const handleClose = () => {
    if (!processing) onClose();
  };

  const handleSaveLots = () => {
    setProcessing(true);

    FulfillmentService.updatePackageGroupLots(
      packageGroup?.fulfillment?.packageGroupFulfillmentId ?? 0,
      fulfillmentItems,
    )
      .then(() => {
        onClose();
      })
      .finally(() => {
        setProcessing(false);
      });
  };
  const canAdd = ordered - total;
  return (
    <Dialog open={packageGroup !== undefined && !!itemPendingFulfillment} onClose={handleClose}>
      <DialogTitle>
        <Typography variant="subtitle2">
          Edit Lot IDs of <strong>{itemPendingFulfillment?.product.name}</strong> for
        </Typography>
        <Typography variant="h5">{packageGroup?.customer?.companyName}</Typography>
      </DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Lot ID</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>UoM</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fulfillmentItems.map((item, index) => {
              const deleted = item.quantity == 0;
              return (
                <TableRow key={`ffItem-${index}`}>
                  <TableCell style={{ opacity: deleted ? 0.5 : 1 }}>{item.lotId}</TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={item.quantity}
                      onChange={(e) => editFulfillmentItem(item.fulfillmentItemId, +e.target.value)}
                      style={{ opacity: deleted ? 0.5 : 1 }}
                    />
                  </TableCell>
                  <TableCell>{itemPendingFulfillment?.product.unitOfMeasure}</TableCell>
                  <TableCell>
                    {!deleted && (
                      <Link style={{ cursor: 'pointer' }} onClick={() => handleDeleteItem(item.fulfillmentItemId)}>
                        Delete
                      </Link>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Table>
            <TableBody>
              <TableCell>
                <TextField
                  label="LotID"
                  variant="outlined"
                  disabled={!canAdd}
                  value={addID}
                  onChange={(e) => handleAddIdChanged(e.target.value)}
                />
              </TableCell>
              <TableCell>
                <TextField
                  label="Quantity"
                  variant="outlined"
                  type="number"
                  disabled={!canAdd}
                  value={addQuantity}
                  onChange={(e) => handleAddQuantityChanged(e.target.value)}
                />
              </TableCell>
              <TableCell>
                <Button disabled={!canAdd || !addID || !addQuantity} onClick={handleAdd}>
                  Add
                </Button>
              </TableCell>
            </TableBody>
          </Table>
        </Grid>
      </DialogActions>
      <DialogActions>
        <Grid container>
          <Grid item flexGrow={1} pl={2}>
            {processing && <CircularProgress size={32} />}
            {!processing && (
              <Typography>
                Total: {total} / {ordered}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Button variant="text" color="error" onClick={handleClose} disabled={processing}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              disabled={processing || !packageGroup || !!canAdd}
              onClick={handleSaveLots}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

import { Accordion, AccordionSummary, Grid, Typography } from '@mui/material';
import './style.scss';

import CardHeader from '../Card/CardHeader';
import CardBody from '../Card/CardBody';
import Card from '../Card/Card';

import { ArrowDropUp, ArrowDropDown } from '@material-ui/icons';

import { SortProperties } from '../../store/config/types';
import SingleItemPendingFulfillmentAccordion from './SingleItemPendingFulfillmentAccordion';
import { ItemPendingFulfillmentStruct } from '../../store/config/types/warehouseWork.types';

interface ItemsPendingFulfillmentAccordionProps {
  items: ItemPendingFulfillmentStruct[];
  title: string;
  timezone?: string;
  sortProps?: SortProperties;
  visibleStatuses?: string[];
  visibleTypes?: string[];
  /* eslint-disable */
  onSortClick?: (field: string) => void;
  onOperation?: (operationName: string, operationParams?: any) => void;
  // onError?: (message?: string) => void;
  /* eslint-enable */
}
const ItemsPendingFulfillmentAccordion = ({
  items,
  title,
  timezone,
  sortProps,
  visibleStatuses,
  visibleTypes,
  onSortClick,
  onOperation,
}: ItemsPendingFulfillmentAccordionProps) => {
  const handleSortLinkClick = (field: string) => {
    if (onSortClick) onSortClick(field);
  };
  const SortableField = (props: { title: string; field: string; sortProps?: SortProperties }) => {
    const order = props.sortProps ? props.sortProps.order : 'ASC';
    const sortArrow = <span>{order === 'DESC' ? <ArrowDropDown /> : <ArrowDropUp />}</span>;
    return sortProps ? (
      <a href="javascript:;" onClick={() => handleSortLinkClick(props.field)}>
        {props.title}
        {props.sortProps?.field === props.field && sortArrow}
      </a>
    ) : (
      <div>{props.title}</div>
    );
  };

  return (
    <Card>
      {title && (
        <CardHeader color="primary" className="delivery-order-header">
          <Grid container>
            <Grid item flexGrow={1}>
              <span>{title}</span>
            </Grid>
          </Grid>
        </CardHeader>
      )}
      <CardBody>
        <Accordion className="item-pending-fulfillment-accordion" expanded={false}>
          <AccordionSummary className="ipf-head title" expandIcon={<span style={{ width: 24 }}></span>}>
            <Typography className="ipf-label" style={{ marginLeft: 24 }}>
              Product ID
            </Typography>
            <Typography className="ipf-name sortable-field">
              <SortableField field="name" title="Name" sortProps={sortProps} />
            </Typography>
            <Typography className="ipf-description">Type</Typography>
            <Typography className="ipf-ordered">Ordered</Typography>
            <Typography className="ipf-committed">Committed</Typography>
            <Typography className="ipf-fulfilled ">Fulfilled</Typography>
            <Typography className="ipf-status sortable-field">
              <SortableField field="status" title="Status" sortProps={sortProps} />
            </Typography>
          </AccordionSummary>
        </Accordion>
        {items.map((item, index) => {
          return (
            <SingleItemPendingFulfillmentAccordion
              key={`accordion-${index}`}
              item={item}
              index={index}
              timezone={timezone}
              onOperation={onOperation}
              visibleTypes={visibleTypes}
              visibleStatuses={visibleStatuses}
            />
          );
        })}
      </CardBody>
    </Card>
  );
};

export default ItemsPendingFulfillmentAccordion;

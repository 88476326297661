import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { ItemPendingFulfillmentStruct } from '../../store/config/types';
import { useEffect, useState } from 'react';
import { FulfillmentService } from '../../services/warehouseWork.service';

export interface ChangeLotDialogProps {
  lotIdToChange?: string | null;
  itemPendingFulfillment?: ItemPendingFulfillmentStruct;
  onClose: () => void;
}
export default function ChangeLotDialog({ lotIdToChange, onClose, itemPendingFulfillment }: ChangeLotDialogProps) {
  const [newValue, setNewValue] = useState(lotIdToChange);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    setNewValue(lotIdToChange);
  }, [lotIdToChange]);

  const handleClose = () => {
    if (!processing) onClose();
  };

  const handleChangeLot = () => {
    setProcessing(true);
    const allWorkOrderIds: number[] = [];
    itemPendingFulfillment?.packageGroups.forEach((pg) => {
      allWorkOrderIds.push(...(pg.fulfillment?.warehouseWorkOrders.map((w) => w.warehouseWorkOrderId) ?? []));
    });
    FulfillmentService.changeDefaultLot(allWorkOrderIds, itemPendingFulfillment?.product.productId ?? 0, newValue ?? '')
      .then(() => {
        onClose();
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  return (
    <Dialog open={lotIdToChange !== undefined && !!itemPendingFulfillment} onClose={handleClose}>
      <DialogTitle>
        <Typography variant="subtitle2">Change Default Lot For </Typography>
        <Typography variant="h5">{itemPendingFulfillment?.product.name}</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container pt={2}>
          <TextField
            fullWidth
            variant="outlined"
            label="Default Lot"
            name="lot"
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
            disabled={processing}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item flexGrow={1} pl={2}>
            {processing && <CircularProgress size={32} />}
          </Grid>
          <Grid item>
            <Button variant="text" color="error" onClick={handleClose} disabled={processing}>
              Cancel
            </Button>
            <Button variant="contained" color="success" disabled={processing || !newValue} onClick={handleChangeLot}>
              Change All
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

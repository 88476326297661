import { TableRow, TableCell, IconButton, Grid, Tabs, Tab } from '@mui/material';
import { AssemblyItemPendingProductionStruct } from '../../store/config/types';

import { SyntheticEvent, useState } from 'react';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import BuildOrdersOfAssemblyItem from './WorkordersOfAssemblyItem';

/* eslint-disable */
enum TabsName {
  WORKORDERS = 'Workorders',
  BUILD_LOTS = 'Build Lots',
}
/* eslint-enable */
export interface AssemblyPendingProductionTableRowProps {
  assemblyItem: AssemblyItemPendingProductionStruct;
  timezone?: string;
  key: string;
  // eslint-disable-next-line
  onAction?: (actionName: string, params: any) => void;
}
export default function AssemblyPendingProductionTableRow({
  assemblyItem,
  onAction,
}: AssemblyPendingProductionTableRowProps) {
  const [isExpanded, setExpanded] = useState(false);
  const [tabValue, setTabValue] = useState(TabsName.WORKORDERS);
  const style = () => (isExpanded ? { borderBottom: 'none' } : {});
  const handleTabChange = (event: SyntheticEvent<Element, Event>, value: TabsName) => {
    setTabValue(value);
  };
  const isLotNumbredItem = assemblyItem.product?.type.toLowerCase().includes('lot');
  const info = { ordered: 0, built: 0 };
  assemblyItem.assemblyBuilds.map((assemblyBuild) => {
    assemblyBuild.warehouseWorkOrders.forEach((wo) => {
      const pIndex = wo.assemblyBuildItems.findIndex((abi) => abi.productId == assemblyItem.product.productId);
      if (pIndex >= 0) {
        info.built += wo.assemblyBuildItems[pIndex].builtQuantity;
        info.ordered += wo.assemblyBuildItems[pIndex].quantity;
      }
    });
  });

  return (
    <>
      <TableRow
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setExpanded(!isExpanded);
        }}
      >
        <TableCell style={style()}>{assemblyItem.product.externalId}</TableCell>
        <TableCell style={style()}>{assemblyItem.product.name}</TableCell>
        <TableCell style={style()}>{assemblyItem.product.description}</TableCell>
        <TableCell style={style()}>{info.ordered}</TableCell>
        <TableCell style={style()}>{info.built}</TableCell>

        <TableCell style={style()} align="right">
          <IconButton
            onClick={() => {
              setExpanded(!isExpanded);
            }}
          >
            {isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
      </TableRow>
      {isExpanded && (
        <TableRow>
          <TableCell colSpan={7} style={{ borderColor: 'black' }}>
            <Grid container p={1}>
              <Tabs value={tabValue} onChange={handleTabChange}>
                <Tab label="WorkOrders" value={TabsName.WORKORDERS} />
                {isLotNumbredItem && <Tab label="Build Lots" value={TabsName.BUILD_LOTS} />}
              </Tabs>
            </Grid>
            <Grid container mb={2}>
              {tabValue == TabsName.WORKORDERS ? (
                <BuildOrdersOfAssemblyItem assemblyItem={assemblyItem} onAction={onAction} />
              ) : (
                <></>
              )}
            </Grid>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

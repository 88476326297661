import { useEffect, useState, Fragment, SyntheticEvent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
// import { ThunkDispatch } from 'redux-thunk';
import moment from 'moment-timezone';
import { AssemblyBuild, AssemblyBuildItem, RootState } from '../../store/config/types';
// import { SortProperties } from '../../store/config/types/deliveryOrders.types';

import { ThunkDispatch } from 'redux-thunk';
import { setToast } from '../../store/actions/toast.actions';

import { CircularProgress, Grid, IconButton, Tab, Tabs } from '@mui/material';

import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import CardBody from '../../components/Card/CardBody';

import { AccessTime, BlurCircular, Brightness1, Brightness5, Refresh } from '@material-ui/icons';
import InfoCard from '../../components/InfoCard/InfoCard';
import { AssemblyBuildStatus, AssemblyBuildStatusReadableStatus } from '../../store/config/enums/warehouseWork.enum';
import { SearchBox, SelectableFiltersStruct } from '../../components/SearchBox/SearchBox';
import { AssemblyBuildService } from '../../services/warehouseWork.service';
import AssemblyBuildWorkorderTable from '../../components/AssemblyBuildComponents/AssemblyBuildWorkorderTable';
import AssemblyPendingProductionTable from '../../components/AssemblyBuildComponents/AssemblyPendingProductionTable';
import ChangeLotOfAssemblyItemDialog from './ChangeLotOfAssemblyBuildItemDialog';

/* eslint-disable */
const PAGE_ITEMS: number = 12;

enum PageModes {
  ASSEMBLY_BUILD_WORKORDER = 'Assembly Build Workorders',
  ASSEMBLIES_PENDING_PRODUCTION = 'Assemblies Pending Production',
}

const Icons = {
  [AssemblyBuildStatus.PENDING]: <AccessTime />,
  [AssemblyBuildStatus.PROCESSING]: <BlurCircular />,
  [AssemblyBuildStatus.BUILT]: <Brightness5 />,
  [AssemblyBuildStatus.INSUFFICIENT_INVENTORY]: <Brightness1 />,
};
/* xxxeslint-enable */

const mapStateToProps = (state: RootState) => {
  return {
    auth: state.auth,
    loggedIn: state.auth.loggedIn,
  };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  setToast: (message: string, messageType: string) => dispatch(setToast(message, messageType)),
});
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function InventoryManagement({ auth, loggedIn, setToast }: PropsFromRedux) {
  if (auth && auth.account && auth.account.shipper?.timezone) moment.tz.setDefault(auth.account.shipper.timezone);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState<string>('');
  const [creationDate, setCreationDate] = useState<string>(moment().format('YYYY-MM-DD'));
  const [shippingDate, setShippingDate] = useState<string>(moment().format('YYYY-MM-DD'));
  const [selectableFilters, setSelectableFilters] = useState<SelectableFiltersStruct>({});

  const [assemblyBuilds, setAssemblyBuilds] = useState<AssemblyBuild[]>([]);

  const [loading, setLoading] = useState(false);
  const [needsRefresh, setNeedsRefresh] = useState<boolean>(false);

  const [pageMode, setPageMode] = useState<PageModes>(PageModes.ASSEMBLY_BUILD_WORKORDER);

  // const [sortProps, setSortProps] = useState<SortProperties>({ field: 'name', order: 'DESC' });
  const [itemToChangeLot, setItemToChangeLot] = useState<AssemblyBuildItem>();

  const [tabValue, setTabValue] = useState(0);

  const [statuses, setStatuses] = useState<any>({
    [AssemblyBuildStatus.PENDING]: 0,
    [AssemblyBuildStatus.INSUFFICIENT_INVENTORY]: 0,
    [AssemblyBuildStatus.PROCESSING]: 0,
    [AssemblyBuildStatus.BUILT]: 0,
  });

  const handleTabChange = (event: SyntheticEvent, value: number) => {
    setTabValue(value);
    setPageMode(value == 0 ? PageModes.ASSEMBLY_BUILD_WORKORDER : PageModes.ASSEMBLIES_PENDING_PRODUCTION);
  };

  const handleOperation = (op: string, params: any) => {
    if (op == 'change-lot-of-assembly-build-item') {
      setItemToChangeLot(params);
    }
  };

  const handleChangeLotDialogClose = () => {
    setItemToChangeLot(undefined);
    setNeedsRefresh(true);
  };

  // const handleSortClick = (field: string) => {
  //   if (sortProps.field === field) {
  //     setSortProps({ ...sortProps, order: sortProps.order === 'ASC' ? 'DESC' : 'ASC' });
  //   } else {
  //     setSortProps({ ...sortProps, field });
  //   }
  // };

  const fetchWorkOrders = () => {
    if (auth && auth.account && auth.account.shipper?.timezone) moment.tz.setDefault(auth.account.shipper.timezone);
    if (auth.account?.shipper?.shipperId) {
      return AssemblyBuildService.fetchAssemblyBuildWorkorders({ creationDate }).then((fechedAssembliyBuilds) => {
        const statusesCount: any = {
          [AssemblyBuildStatus.PENDING]: 0,
          [AssemblyBuildStatus.INSUFFICIENT_INVENTORY]: 0,
          [AssemblyBuildStatus.PROCESSING]: 0,
          [AssemblyBuildStatus.BUILT]: 0,
        };
        fechedAssembliyBuilds.forEach((assemblyBuild) => {
          statusesCount[assemblyBuild.status] = statusesCount[assemblyBuild.status] + 1;
        });
        setStatuses(statusesCount);
        setAssemblyBuilds(fechedAssembliyBuilds);
      });
    }
  };

  const refetchData = async () => {
    setNeedsRefresh(false);
    if (!loading) {
      setLoading(true);
      Promise.all([fetchWorkOrders()])
        .then(() => {
          setCurrentPage(1);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    setNeedsRefresh(true);
  }, [loggedIn, auth.account]);

  useEffect(() => {
    if (needsRefresh && !loading) {
      refetchData();
    }
  }, [needsRefresh]);

  const handlePageClick = (page: number) => setCurrentPage(page);

  const handleFilterChange = (filters: any) => {
    setCreationDate(filters.creationDate);
    setShippingDate(filters.shippingDate);
    setNeedsRefresh(true);
  };

  return (
    <Fragment>
      <Grid container spacing={1} margin={1}>
        {Object.keys(statuses).map((status: string, index) => {
          return (
            <Grid item key={`status-${index}`}>
              <InfoCard
                caption={AssemblyBuildStatusReadableStatus[status as AssemblyBuildStatus]}
                number={statuses[status]}
                icon={Icons[status as AssemblyBuildStatus]}
                loading={loading}
              />
            </Grid>
          );
        })}
      </Grid>

      <SearchBox
        query={''}
        onQueryChange={setSearchText}
        disabled={loading}
        dateFilters={{ creationDate }}
        selectableFilters={selectableFilters}
        onFilterApplying={handleFilterChange}
        captions={{
          creationDate: 'Creation Date',
          shippingDate: 'Shipping Date',
          orderDate: 'Order Date',
          shipVia: 'Ship Via',
        }}
      />
      <Card>
        <CardHeader color="primary">
          <Grid container p={0} m={0} alignItems="center">
            <Grid item flexGrow={1}>
              <span>Inventory Management</span>
            </Grid>
            <Grid item alignItems="center">
              <IconButton
                size="small"
                onClick={() => {
                  if (!loading) setNeedsRefresh(true);
                }}
              >
                {loading ? (
                  <CircularProgress size={24} style={{ color: 'white' }} />
                ) : (
                  <Refresh style={{ color: 'white' }} />
                )}
              </IconButton>
            </Grid>
          </Grid>
        </CardHeader>
        <CardBody>
          <Grid container>
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label={PageModes.ASSEMBLY_BUILD_WORKORDER} />
              <Tab label={PageModes.ASSEMBLIES_PENDING_PRODUCTION} />
            </Tabs>
          </Grid>
          <Grid container>
            {pageMode == PageModes.ASSEMBLY_BUILD_WORKORDER && (
              <AssemblyBuildWorkorderTable
                assemblyBuilds={assemblyBuilds}
                timezone={auth?.account?.shipper?.timezone}
              />
            )}
            {pageMode == PageModes.ASSEMBLIES_PENDING_PRODUCTION && (
              <AssemblyPendingProductionTable
                assemblyBuilds={assemblyBuilds}
                timezone={auth?.account?.shipper?.timezone}
                onAction={handleOperation}
              />
            )}
          </Grid>
        </CardBody>
      </Card>
      <ChangeLotOfAssemblyItemDialog
        onClose={handleChangeLotDialogClose}
        assemblyBuildItemIds={itemToChangeLot ? [itemToChangeLot.assemblyBuildItemId] : undefined}
        product={itemToChangeLot?.product}
        lotIdToChange={itemToChangeLot?.lotId}
        onNotify={setToast}
      />
    </Fragment>
  );
}

export default connector(InventoryManagement);

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { ItemPendingFulfillmentStruct } from '../../store/config/types';
import { useEffect, useState } from 'react';
import { AssemblyBuildService } from '../../services/warehouseWork.service';

export interface CreateAssemblyWorkorderDialogProps {
  itemPendingFulfillment?: ItemPendingFulfillmentStruct;
  defaultCount: number;
  onClose: () => void;
  // eslint-disable-next-line
  onNotify?: (message: string, type: string) => void;
}
export default function CreateAssemblyWorkorderDialog({
  onClose,
  onNotify,
  itemPendingFulfillment,
  defaultCount,
}: CreateAssemblyWorkorderDialogProps) {
  const [assemblyCount, setAssemblyCount] = useState(defaultCount);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    setAssemblyCount(defaultCount);
  }, [defaultCount]);

  const handleClose = () => {
    if (!processing) onClose();
  };

  const handleSubmit = () => {
    setProcessing(true);
    AssemblyBuildService.createAssemblyWorkorder(itemPendingFulfillment?.product.productId ?? 0, assemblyCount)
      .then(() => {
        if (onNotify) onNotify('Assembly workorder successfuly created.', 'success');
        onClose();
      })
      .catch((err: any) => {
        if (onNotify) onNotify(err.message, 'danger');
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  return (
    <Dialog open={!!itemPendingFulfillment} onClose={handleClose}>
      <DialogTitle>
        <Typography variant="subtitle2">Change Default Lot For </Typography>
        <Typography variant="h5">{itemPendingFulfillment?.product.name}</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container pt={2}>
          <TextField
            fullWidth
            variant="outlined"
            type="number"
            label="Count"
            name="assemblyCount"
            value={defaultCount}
            onChange={(e) => setAssemblyCount(+e.target.value)}
            disabled={processing}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item flexGrow={1} pl={2}>
            {processing && <CircularProgress size={32} />}
          </Grid>
          <Grid item>
            <Button variant="text" color="error" onClick={handleClose} disabled={processing}>
              Cancel
            </Button>
            <Button variant="contained" color="success" disabled={processing || !assemblyCount} onClick={handleSubmit}>
              Create
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

import moment from 'moment-timezone';
import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
// import AddButton from '../../components/CustomButtons/AddButton';
import CustomDialog from '../../components/CustomDialog/CustomDialog';
import tripsService from '../../services/trips.service';
import { fetchCouriers } from '../../store/actions/couriers.actions';
import { setToast } from '../../store/actions/toast.actions';
import { deleteTripOffer, fetchAliveTrips, fetchTrips } from '../../store/actions/trips.actions';
import {
  CustomerLocationOrder,
  Package,
  RootState,
  Trip,
  TripStopLabelStruct,
  TripsOfDate,
} from '../../store/config/types';
import OfferTripDialog from './OfferTripDialog';
import TripDetails from './TripDetails';
import TripStopPackages from './TripStopPackages';
import TripView from '../../components/TripsDnd/TripView';
import { hasPermission } from '../../utils/permissions';
import { deliveryOrdersService } from '../../services/deliveryOrders.service';
import { convertTripToTripWithStopsDetails, isAlliveTrip, stopSortBySchedulerTimes } from '../../utils/trips.helper';
import {
  ThirdPartyDeliveryCancelDialog,
  ThirdPartySupportDialog,
} from '../../components/ThirdPartyDeliveryDialog/ThirdPartyDeliveryDialogs';
import { GeoAnalyzeService } from '../../services/geoAnalyze.service';
import { fetchWarehouses } from '../../store/actions/warehouses.actions';
import { getLabelsOfTripStops } from '../../components/TripsDnd/helper';

import CSVDialog from '../../components/CSVImporter/CSVDialog';

const AUTO_REFRESH_INTERVAL: number = 60 * 1000;

var timerHandle: any = null;

const mapStateToProps = (state: RootState) => {
  return {
    auth: state.auth,
    trips: state.trips,
    couriers: state.couriers,
    warehouses: state.warehouses,
    loggedIn: state.auth.loggedIn,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  fetchTrips: (from: Date | null = null, to: Date | null = null) => dispatch(fetchTrips(from, to)),
  fetchAliveTrips: (tripIds: number[]) => dispatch(fetchAliveTrips(tripIds)),
  fetchCouriers: () => dispatch(fetchCouriers()),
  fetchWarehouses: (shipperId: number) => dispatch(fetchWarehouses(shipperId)),
  deleteTripOffer: (tripOfferId: number) => dispatch(deleteTripOffer(tripOfferId)),
  setToast: (message: string, messageType: string) => dispatch(setToast(message, messageType)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function Trips({
  trips,
  couriers,
  warehouses,
  auth,
  fetchTrips,
  fetchAliveTrips,
  fetchCouriers,
  deleteTripOffer,
  fetchWarehouses,
  setToast,
  loggedIn,
}: PropsFromRedux) {
  const history = useHistory();
  const [date, setDate] = useState<string>(moment().format('YYYY-MM-DD'));
  const [tripToOffer, setTripToOffer] = useState<Trip | null>(null);
  const [tripDetailsId, setTripDetailsId] = useState<number | null>(null);
  const [tripDeleteId, setTripDeleteId] = useState<number | null>(null);
  const [stopPackages, setStopPackages] = useState<Package[] | null>(null);
  const [openDialogDelete, setOpenDialogDelete] = useState<boolean>(false);
  const [lastTripUpdate, setLastTripUpdate] = useState(new Date());
  const [tripsOfDay, setTripsOfDay] = useState<TripsOfDate>();
  const [unAssignedOrders, setUnAssignedOrders] = useState<CustomerLocationOrder[]>([]);
  const [tripsSorting, setTripsSorting] = useState(false);
  const [thirdPartyToCancel, setThirdPartyToCancel] = useState<number | null>(null);
  const [thirdPartySupportTripId, setThirdPartySupportTripId] = useState<number>();
  const [labels, setLabels] = useState<TripStopLabelStruct[]>([]);
  const [csvOpen, setCsvOpen] = useState(false);

  const strToDate = (str: string) => moment(str, 'YYYY-MM-DD').startOf('day').toDate();

  const timezone = auth && auth.account && auth.account.shipper?.timezone;
  timezone && moment.tz.setDefault(timezone);

  useEffect(() => {
    if (!trips.loadingTrips && !trips.trips && !trips.tripsErrorMessage) {
      const tripsDate = date ? strToDate(date) : moment().startOf('day').toDate();
      fetchTrips(tripsDate);
      setLastTripUpdate(new Date());
    }
  }, [fetchTrips, trips.trips, trips.loadingTrips, loggedIn]);

  useEffect(() => {
    if (!warehouses.loadingWarehouses && !warehouses.warehouses && !warehouses.warehousesErrorMessage && auth.account) {
      fetchWarehouses(auth.account?.shipper?.shipperId ?? 0);
    }
  }, [
    fetchWarehouses,
    warehouses.warehouses,
    warehouses.warehousesErrorMessage,
    warehouses.loadingWarehouses,
    auth.account,
  ]);

  useEffect(() => {
    setTripsSorting(true);
    fetchTrips(strToDate(date));
  }, [date]);

  useEffect(() => {
    if (
      !couriers.loadingCouriers &&
      !couriers.couriers &&
      !couriers.couriersFetched &&
      !couriers.couriersErrorMessage
    ) {
      fetchCouriers();
    }
  }, [
    fetchCouriers,
    couriers.loadingCouriers,
    couriers.couriers,
    couriers.couriersFetched,
    couriers.couriersErrorMessage,
  ]);
  const getAliveTripsIds = () => {
    return trips.trips?.filter((trip) => isAlliveTrip(trip)).map((trip) => trip.tripId ?? 0) ?? [];
  };
  const updateInfo = (tripsIds?: number[]) => {
    fetchAliveTrips(tripsIds ?? getAliveTripsIds());
    fetchCouriers();
    setLastTripUpdate(new Date());
  };

  useEffect(() => {
    if (!trips.trips?.length) return;
    if (timerHandle) clearInterval(timerHandle);

    if (date && moment().isSame(strToDate(date), 'day')) {
      const tripsIds = getAliveTripsIds();
      timerHandle = setInterval(() => {
        updateInfo(tripsIds);
      }, AUTO_REFRESH_INTERVAL);
      setLastTripUpdate(new Date());
      return () => clearInterval(timerHandle);
    }
  }, [trips.trips]);

  useEffect(() => {
    if (trips.loadingAliveTrips || trips.loadingTrips === null) return;

    let tripsToShow = trips.trips ?? [];
    if (trips.trips) {
      // filter date
      if (date)
        tripsToShow = tripsToShow?.filter((trip) => {
          return moment(date).isSame(trip.startsAt, 'day');
        });
      tripsToShow.forEach((trip) => trip.tripStops.sort(stopSortBySchedulerTimes));
    }

    const newDateGroups: TripsOfDate = {
      date: moment(date).format('YYYY-MM-DD'),
      trips: tripsToShow,
    };
    setTripsOfDay(newDateGroups);
    setLabels(getLabelsOfTripStops(tripsToShow));
    setUnAssignedOrders(trips.unAssignedOrders ?? []);
  }, [trips.loadingTrips, trips.loadingAliveTrips]);

  useEffect(() => {
    if (tripsSorting && !trips.loadingTrips) setTripsSorting(false);
  });

  const handleUpdateClicked = () => {
    updateInfo();
  };

  const handleDateChane = (e: ChangeEvent<HTMLInputElement>) => {
    if (moment(e.target.value).isValid()) {
      setDate(moment(e.target.value).format('YYYY-MM-DD'));
    }
  };

  const handleCreateTrip = (dateString?: string) =>
    history.push(`/trips/create${dateString ? '?date=' + moment(dateString).format('YYYY-MM-DD') : ''}`);

  const handleDetailTrip = (tripId: number) => setTripDetailsId(tripId);
  const handleDeleteTrip = (tripId: number) => {
    setTripDeleteId(tripId);
    setOpenDialogDelete(true);
  };

  const handleClosePackages = () => setStopPackages(null);

  const handleCloseDetails = () => setTripDetailsId(null);

  const handleCloseDialogDelete = () => {
    setOpenDialogDelete(false);
    setTripDeleteId(null);
  };

  const handleCustomActions = async (params: any, actionName: string) => {
    if (actionName === 'sendToToday') {
      try {
        await deliveryOrdersService.changeDateofPackageGroups(
          params.packageGroupsIds,
          moment().startOf('day').toDate(),
        );
        setUnAssignedOrders(
          unAssignedOrders.map((order) => {
            if (params.packageGroupsIds.includes(order.packageGroups[0].packageGroupId)) {
              return { ...order, deliveryExpectedAt: moment().startOf('day').toDate() };
            } else return order;
          }),
        );
      } catch (err) {
        console.error(err);
      }
    }
    if (actionName === 'CSV') {
      setCsvOpen(true);
    }
  };
  const tripsActionSelect = (tripId: number | undefined, menuAction: string) => {
    if (tripId)
      switch (menuAction) {
        case 'details':
          handleDetailTrip(tripId);
          break;
        case 'delete':
          handleDeleteTrip(tripId);
          break;
        case 'offer':
          setTripToOffer(trips.trips?.find((t) => t.tripId === tripId) ?? null);
          break;
        case 'remove-offer':
          const trip = trips.trips?.find((t) => t.tripId === tripId);
          if (trip && Array.isArray(trip.tripOffers)) {
            try {
              deleteTripOffer(trip.tripOffers[0].tripOfferId).then(() => {
                updateInfo();
              });
            } catch (error: any) {
              setToast(error, 'danger');
            }
          }
          break;

        case 'cancel-third-party':
          setThirdPartyToCancel(tripId);
          break;
        case 'third-party-support':
          setThirdPartySupportTripId(tripId);
          break;
        case 'compute-stops':
          if (auth.account && hasPermission(auth.account, ['analyze.geoanalyze.exec']) && tripId) {
            GeoAnalyzeService.analyzeTripActualsByGeoLocations(tripId).then(() => {
              setToast('Computing was done successfully', 'success');
              updateInfo([tripId]);
            });
          }
          break;
        default:
          alert(menuAction);
      }
  };

  const deleteTrip = async () => {
    if (!tripDeleteId) return;

    tripsService
      .deleteTrip(tripDeleteId)
      .then(() => {
        setToast('Trip successfully deleted', 'success');
        fetchTrips(strToDate(date));
      })
      .catch((err) => {
        setToast(err.data.message, 'danger');
      });

    handleCloseDialogDelete();
  };

  const handleOfferDialogClose = () => {
    setTripToOffer(null);
    updateInfo();
  };

  const refreshToday = () => {
    fetchAliveTrips(getAliveTripsIds());
  };

  return (
    <Fragment>
      <TripView
        date={tripsOfDay?.date ?? date}
        trips={tripsOfDay?.trips ?? []}
        warehouses={warehouses.warehouses ?? []}
        unAssignedOrders={
          unAssignedOrders?.filter((order) =>
            moment(order.deliveryExpectedAt).isSame(moment(tripsOfDay?.date ?? date, 'YYYY-MM-DD'), 'day'),
          ) ?? []
        }
        labels={labels}
        shipperId={auth.account?.shipper?.shipperId}
        couriers={couriers.couriers ?? []}
        onTripMenuAction={tripsActionSelect}
        onCustomAction={handleCustomActions}
        onUpdateClicked={handleUpdateClicked}
        handleDateChane={handleDateChane}
        lastTripUpdate={lastTripUpdate}
        timezone={auth?.account?.shipper?.timezone}
        computedToggle={auth.account ? hasPermission(auth.account, ['analyze.geoanalyze.view']) : false}
        canComputeStops={auth.account ? hasPermission(auth.account, ['analyze.geoanalyze.exec']) : false}
        onNeedRefresh={refreshToday}
        onActionButtonClick={(tripIds, actionName) => {
          if (actionName === 'edit')
            history.push(`/trips/${moment(tripsOfDay?.date ?? date).format('YYYY-MM-DD')}/edit`);
          else if (actionName === 'create') handleCreateTrip(tripsOfDay?.date ?? date);
          else console.log('Unhandled command: ' + actionName, tripIds);
        }}
      />
      {/* {auth.account && hasPermission(auth.account, ['shipperAdmin.trips.create']) && (
        <AddButton onClick={() => handleCreateTrip()} />
      )} */}
      {tripDetailsId && (
        <TripDetails
          selectedTripId={tripDetailsId}
          handleClose={handleCloseDetails}
          setStopPackages={setStopPackages}
        />
      )}
      {stopPackages?.length && <TripStopPackages packages={stopPackages} handleClosePackages={handleClosePackages} />}
      <OfferTripDialog open={tripToOffer !== null} trip={tripToOffer} handleClose={handleOfferDialogClose} />
      <CustomDialog
        title={'Confirm'}
        open={openDialogDelete || tripDeleteId}
        description={`¿Are you sure you want to delete trip ${tripDeleteId}?`}
        onConfirmation={deleteTrip}
        handleClose={handleCloseDialogDelete}
        okButtonText={'Yes'}
        cancelButtonText={'Cancel'}
      />

      <ThirdPartyDeliveryCancelDialog
        open={!!thirdPartyToCancel}
        trip={convertTripToTripWithStopsDetails(trips.trips?.find((trip) => trip.tripId === thirdPartyToCancel))}
        onClose={() => {
          updateInfo();
          setThirdPartyToCancel(null);
        }}
      />
      <ThirdPartySupportDialog
        open={!!thirdPartySupportTripId}
        trip={convertTripToTripWithStopsDetails(trips.trips?.find((trip) => trip.tripId === thirdPartySupportTripId))}
        onClose={() => {
          setThirdPartySupportTripId(undefined);
        }}
      />
      <CSVDialog open={csvOpen} setOpen={setCsvOpen} subject="trips" title="Import Trips From CSV" />
    </Fragment>
  );
}

export default connector(Trips);

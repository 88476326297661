import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { MuiThemeProvider, CssBaseline } from '@material-ui/core';
import { store } from './store/config/configureStore';
import Main from './screens/Main';
import { theme } from './styles/theme';
import './styles/app.scss';

function App() {
  return (
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <BrowserRouter>
          <CssBaseline />
          <Main />
        </BrowserRouter>
      </MuiThemeProvider>
    </Provider>
  );
}

export default App;

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Link,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './style.scss';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import { PackageGroup } from '../../store/config/types';
import { SyntheticEvent, useState } from 'react';
import {
  PackageGroupFulfillmentReadableStatus,
  PackageGroupFulfillmentStatus,
} from '../../store/config/enums/warehouseWork.enum';
import moment from 'moment-timezone';
import { getfulfillmentSummaryOfPackageGroup } from '../../utils/fulfillment.helpers';
import { fixNumber } from '../../utils/string.helpers';
import { ItemPendingFulfillmentStruct } from '../../store/config/types/warehouseWork.types';
import { Add } from '@material-ui/icons';
// import { useHistory } from 'react-router-dom';

/* eslint-disable */
enum TabPages {
  DELIVERY_ORDERS = 0,
  FULFILLMENT_LOTS = 1,
  ASSEMBLY_ORDERS = 2,
}
/* eslint-enable */
export interface SingleItemPendingFulfillmentAccordionProps {
  item: ItemPendingFulfillmentStruct;
  index: number;
  timezone?: string;
  visibleStatuses?: string[];
  visibleTypes?: string[];
  /* eslint-disable */
  onOperation?: (operationName: string, operationParams?: any) => void;
  /* eslint-enable */
}

export default function SingleItemPendingFulfillmentAccordion({
  item,
  index,
  visibleStatuses,
  visibleTypes,
  onOperation,
}: SingleItemPendingFulfillmentAccordionProps) {
  const [tabPage, setTabPage] = useState(TabPages.DELIVERY_ORDERS);

  if (visibleTypes && !visibleTypes.includes(item.product.type)) return <></>;

  const changeTab = (event: SyntheticEvent, newValue: TabPages) => {
    setTabPage(newValue);
  };

  function handleLotChangeClick(lotId: string | null): void {
    if (onOperation) onOperation('lot-batch-change', { product: item, lotId });
  }

  function handlePackageGroupLotClick(packageGroup: PackageGroup): void {
    if (onOperation) onOperation('edit-package-group-fulfillment-lot', { product: item, packageGroup });
  }

  function handleCreateAssemblyWorkorderClick(count: number): void {
    if (onOperation) onOperation('create-assembly-workorder', { item, count: count <= 0 ? 10 : count });
  }

  const isLotNumbredItem = item.product?.type.toLowerCase().includes('lot');
  const isAssembly = item.product?.type.toLowerCase().includes('assembly');

  const statusClass = '';

  const lotsViewInfo: any[] = [];

  const summary = {
    commited: 0,
    ordered: 0,
    fulfilled: 0,
    status: PackageGroupFulfillmentStatus.PENDING,
    someInsufficient: false,
    someFulfilled: false,
    somePending: false,
    somePartialFulfilled: false,
    someProcessing: false,
  };

  const allWorkOrderIds: number[] = [];

  item.packageGroups.forEach((pg) => {
    if (pg.fulfillment?.warehouseWorkOrders)
      allWorkOrderIds.push(...pg.fulfillment.warehouseWorkOrders.map((w) => w.warehouseWorkOrderId));

    const pgSummary = getfulfillmentSummaryOfPackageGroup(pg);

    summary.commited += pgSummary.commited;
    summary.ordered += pgSummary.ordered;
    summary.fulfilled += pgSummary.fulfilled;
    if (pg.fulfillment) {
      pg.fulfillment.status = pgSummary.status;
      pg.fulfillment.commited = pgSummary.commited;
      pg.fulfillment.ordered = pgSummary.ordered;
      pg.fulfillment.fulfilled = pgSummary.fulfilled;
      pg.fulfillment.lotQty = pgSummary.lotQty;
    }

    switch (pgSummary.status) {
      case PackageGroupFulfillmentStatus.INSUFFICIENT_INVENTORY:
        summary.someInsufficient = true;
        break;
      case PackageGroupFulfillmentStatus.FULFILLED:
        summary.someFulfilled = true;
        break;
      case PackageGroupFulfillmentStatus.PARTIAL_FULFILLED:
        summary.somePartialFulfilled = true;
        break;
      case PackageGroupFulfillmentStatus.PENDING:
        summary.somePending = true;
        break;
      case PackageGroupFulfillmentStatus.PROCESSING:
        summary.someProcessing = true;
        break;
    }

    pg.fulfillment?.lotQty?.forEach((lq) => {
      const lqIndex = lotsViewInfo.findIndex((lvi) => lvi.lotId === lq.lotId);
      if (lqIndex < 0) {
        lotsViewInfo.push({ ...lq });
      } else {
        lotsViewInfo[lqIndex].quantity += lq.quantity;
      }
    });
  });
  if (summary.commited < summary.ordered) {
    summary.status = PackageGroupFulfillmentStatus.INSUFFICIENT_INVENTORY;
    if (summary.somePending) summary.status = PackageGroupFulfillmentStatus.MIXED_STATUS;
  } else if (summary.somePending) {
    summary.status = PackageGroupFulfillmentStatus.PENDING;
  } else if (summary.someProcessing || (summary.someFulfilled && summary.somePending)) {
    summary.status = PackageGroupFulfillmentStatus.PROCESSING;
  } else if (summary.somePartialFulfilled) {
    summary.status = PackageGroupFulfillmentStatus.PARTIAL_FULFILLED;
  } else {
    summary.status = PackageGroupFulfillmentStatus.FULFILLED;
  }

  if (visibleStatuses && !visibleStatuses.includes(summary.status)) return <></>;

  let uom = item.product.unitOfMeasure;
  if (uom == 'Each') uom = '';

  return (
    <Accordion
      className={`item-pending-fulfillment-accordion ${statusClass}`}
      key={`item-pending-fulfillment-${index}`}
      // onChange={handleChange(index)}
    >
      <AccordionSummary className="ipf-head" expandIcon={<ExpandMoreIcon />} aria-controls="">
        <span style={{ marginLeft: 24 }}></span>
        <Typography className="ipf-label">
          <span>{item.product.externalId ?? `P#${item.product.productId}`}</span>
        </Typography>
        <Typography className="ipf-name">{item.product.name}</Typography>
        <Typography className="ipf-description">{item.product.type}</Typography>
        <Typography className={`ipf-ordered`}>
          {fixNumber(summary.ordered)} <span style={{ fontSize: '75%' }}>{uom}</span>
        </Typography>
        <Typography className={`ipf-committed`}>
          {fixNumber(summary.commited)} <span style={{ fontSize: '75%' }}>{uom}</span>
        </Typography>
        <Typography className="ipf-fulfilled">{fixNumber(summary.fulfilled)}</Typography>
        <Typography className="ipf-status">{`${PackageGroupFulfillmentReadableStatus[summary.status]}`}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <GridContainer>
          <GridItem xs={12}>
            <Grid item textAlign="end">
              {isAssembly && (
                <Button
                  variant={
                    summary.status == PackageGroupFulfillmentStatus.INSUFFICIENT_INVENTORY ? 'contained' : 'outlined'
                  }
                  onClick={() => handleCreateAssemblyWorkorderClick(summary.commited - summary.ordered)}
                  color="success"
                >
                  <Add /> Create Assembly Workorder
                </Button>
              )}
            </Grid>
            <Tabs value={tabPage} onChange={changeTab}>
              {item.packageGroups?.length && <Tab label="Delivery Orders" value={TabPages.DELIVERY_ORDERS} />}
              {item.packageGroups?.length && isLotNumbredItem && (
                <Tab label="Fulfillment Lots" value={TabPages.FULFILLMENT_LOTS} />
              )}
            </Tabs>
            {tabPage === TabPages.DELIVERY_ORDERS && (
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Order Date</TableCell>
                    <TableCell>ID</TableCell>
                    <TableCell>Customer</TableCell>
                    {isLotNumbredItem && <TableCell>LotID:Qty</TableCell>}
                    <TableCell>Ordered</TableCell>
                    <TableCell>Committed</TableCell>
                    <TableCell>Fulfilled</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {item.packageGroups.map((pg) => {
                    const customerName = `${pg.customer?.firstName ? pg.customer?.firstName : ''}${
                      pg.customer?.lastName ? ' ' + pg.customer?.lastName : ''
                    }`;

                    const idLabel = pg.additionalData?.label ?? `PG#${pg.packageGroupId}`;
                    const idLink = pg.additionalData?.externalLink ? (
                      <Link href={pg.additionalData?.externalLink} target="_blank">
                        {idLabel}
                      </Link>
                    ) : (
                      idLabel
                    );

                    return (
                      <TableRow key={`ipf-pg-${pg.packageGroupId}`}>
                        <TableCell>{moment(pg.orderedAt).format('MM/DD/YYYY')}</TableCell>
                        <TableCell>{idLink}</TableCell>
                        <TableCell>
                          {pg.customer?.companyName ? pg.customer?.companyName + ' ' : ''}
                          {customerName}
                        </TableCell>
                        {isLotNumbredItem && (
                          <TableCell>
                            {pg.fulfillment?.lotQty?.map((lq) => (
                              <Grid container key={`lotQty-${lq.lotId}`} whiteSpace="nowrap">
                                <Link onClick={() => handlePackageGroupLotClick(pg)} style={{ cursor: 'pointer' }}>
                                  {`${lq.lotId ?? 'Unknown'}`}{' '}
                                  <span style={{ display: 'inline-block', textDecoration: 'none', color: 'black' }}>
                                    {' '}
                                    :{`${fixNumber(lq.quantity)}`}
                                  </span>
                                </Link>
                              </Grid>
                            ))}
                          </TableCell>
                        )}
                        <TableCell>
                          {fixNumber(pg.fulfillment?.ordered)} <span style={{ fontSize: '75%' }}>{uom}</span>
                        </TableCell>
                        <TableCell>
                          {fixNumber(pg.fulfillment?.commited)} <span style={{ fontSize: '75%' }}>{uom}</span>
                        </TableCell>
                        <TableCell>{fixNumber(pg.fulfillment?.fulfilled)}</TableCell>
                        <TableCell>
                          {PackageGroupFulfillmentReadableStatus[pg.fulfillment?.status ?? 'PENDING']}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
            {tabPage === TabPages.FULFILLMENT_LOTS && (
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Lot ID</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lotsViewInfo.map((lotInfo, index) => (
                    <TableRow key={`lotInfo-${index}`}>
                      <TableCell>{lotInfo.lotId ?? 'Unknown'}</TableCell>
                      <TableCell>{fixNumber(lotInfo.quantity)}</TableCell>
                      <TableCell>
                        <Link style={{ cursor: 'pointer' }} onClick={() => handleLotChangeClick(lotInfo.lotId)}>
                          {lotsViewInfo.length > 1 ? 'Set As Default' : 'Change Default Lot'}
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </GridItem>
        </GridContainer>
      </AccordionDetails>
    </Accordion>
  );
}

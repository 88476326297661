import {
  Store,
  Person,
  Info,
  Business,
  AccessTime,
  ArrowUpward,
  ArrowDownward,
  PersonPinCircle,
} from '@material-ui/icons';
import { DndTimeLineChartItem } from '../DndTimelineChart/DndTimelineChart';
import './TripsDnd.styles.scss';
import moment from 'moment-timezone';
import { formatTimeWindow } from '../../utils/string.helpers';
import { TripStopTimeWindowStatuses } from '../../store/config/enums/tripStatusFilter.enum';

interface TripDndItemToolTipProps {
  item: DndTimeLineChartItem;
  onChangeTimeClick?: () => void;
  showTimes?: boolean;
}

export default function TripDndItemToolTip({ item, onChangeTimeClick, showTimes = true }: TripDndItemToolTipProps) {
  return (
    <div className="stop-tooltip">
      {item.additionalInfo?.shipToAddressee && (
        <div className="data-row company-name">
          <PersonPinCircle className="icon" />
          {item.additionalInfo.shipToAddressee}
        </div>
      )}
      {item.additionalInfo?.companyName && (
        <div className="data-row company-name">
          <Store className="icon" />
          {item.additionalInfo.companyName}
        </div>
      )}
      {item.additionalInfo?.name && (
        <div className="data-row display-name">
          <Person className="icon" />
          {item.additionalInfo?.name}
        </div>
      )}
      {item.additionalInfo?.warehouseName && (
        <div className="data-row display-name">
          <Business className="icon" />
          {item.additionalInfo?.warehouseName}
        </div>
      )}
      {item.additionalInfo.skippReason && (
        <div className="data-row info">
          <Info className="icon" />
          {item.additionalInfo.skippReason}
        </div>
      )}

      <div className="data-row">
        {showTimes && (
          <>
            {item.start && <ArrowDownward className="icon" />}
            {item.start && <span className="stop-arrive-time">{moment(item.start).format('hh:mm a')}</span>}
            {item.end && <ArrowUpward className="icon" />}
            {item.end && <span className="stop-departure-time">{moment(item.end).format('hh:mm a')}</span>}
          </>
        )}
        {onChangeTimeClick && (
          <span
            className="stop-arrive-time-change"
            onClick={() => {
              onChangeTimeClick();
            }}
          >
            Change
          </span>
        )}
        {(item.additionalInfo.timeWindowFrom || item.additionalInfo.timeWindowTo) && (
          <span className="stop-time-window">
            <AccessTime className="icon" />
            {formatTimeWindow(item.additionalInfo.timeWindowFrom, item.additionalInfo.timeWindowTo)}
          </span>
        )}
      </div>
      {[TripStopTimeWindowStatuses.TIME_WINDOW_MISSED, TripStopTimeWindowStatuses.TIME_WINDOW_WARNING].includes(
        item.additionalInfo.timeWindowStatus,
      ) && (
        <div className="data-row info">
          <Info className="icon" />
          Outside the Requested Time Window {`${item.additionalInfo.timeWindowAlertReason ?? ''}`}
        </div>
      )}
      {item.additionalInfo.packagesStatus && (
        <div className="data-row info">
          <Info className="icon" />
          {item.additionalInfo.packagesStatus}
        </div>
      )}
    </div>
  );
}

import { useEffect, useState, Fragment, SyntheticEvent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
// import { ThunkDispatch } from 'redux-thunk';
import moment from 'moment-timezone';
import { RootState } from '../../store/config/types';
import { CustomerLocationOrder, PackageGroup, SortProperties } from '../../store/config/types/deliveryOrders.types';
import Pagination from '../../components/Pagination/Pagination';
import { deliveryOrdersService } from '../../services/deliveryOrders.service';
import {
  deliveriesSortFunction,
  fixDeliveryOrderFilters,
  getDeliveryOrderSearchString,
} from '../../utils/deliveryOrders.helpers';
import DeliveryOrdersAccordion from '../../components/DeliveryOrdersAccordion/DeliveryOrdersAccordion';
// import { hasPermission } from '../../utils/permissions';
import { ThunkDispatch } from 'redux-thunk';
import { setToast } from '../../store/actions/toast.actions';
import { SearchBox, SelectableFiltersStruct } from '../../components/SearchBox/SearchBox';

import { CircularProgress, Grid, IconButton, Tab, Tabs } from '@mui/material';

import { FulfillmentService } from '../../services/warehouseWork.service';
import ItemsPendingFulfillmentAccordion from '../../components/ItemPendingFulfillmentAccordion/ItemsPendingFulfillmentAccordion';
import ChangeLotDialog from './ChangeLotDialog';
import PackageGroupLotsDialog from './PackageGroupLotsDialog';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import CardBody from '../../components/Card/CardBody';
import { AccessTime, BlurCircular, Brightness5, Brightness6, Refresh } from '@material-ui/icons';
import InfoCard from '../../components/InfoCard/InfoCard';
import {
  PackageGroupFulfillmentReadableStatus,
  PackageGroupFulfillmentStatus,
} from '../../store/config/enums/warehouseWork.enum';
import { getfulfillmentStatusOfPackageGroups } from '../../utils/fulfillment.helpers';
import { ItemPendingFulfillmentStruct } from '../../store/config/types/warehouseWork.types';
import CreateAssemblyWorkorderDialog from './CreateAssemblyWorkorderDialog';

const PAGE_ITEMS: number = 12;

/* eslint-disable */
enum PageModes {
  ALL_DELIVERY_ORDERS = 'all-delivery-orders',
  ITEMS_PENDING_FULFILLMENT = 'items-pending-fulfillment',
}
/* eslint-enable */

const mapStateToProps = (state: RootState) => {
  return {
    auth: state.auth,
    loggedIn: state.auth.loggedIn,
  };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  setToast: (message: string, messageType: string) => dispatch(setToast(message, messageType)),
});
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function Fulfillment({ auth, loggedIn, setToast }: PropsFromRedux) {
  auth && auth.account && auth.account.shipper?.timezone && moment.tz.setDefault(auth.account.shipper.timezone);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState<string>('');
  const [shippingDate, setShippingDate] = useState(moment().format('YYYY-MM-DD'));
  const [orderDate, setOrderDate] = useState<string>('');

  const [loading, setLoading] = useState(false);
  const [needsRefresh, setNeedsRefresh] = useState(false);
  const [pageMode, setPageMode] = useState<PageModes>(PageModes.ALL_DELIVERY_ORDERS);

  const [allDeliveryItems, setAllDeliveryItems] = useState<CustomerLocationOrder[]>([]);
  const [allItemsPendingFulfillment, setAllItemsPendingFulfillment] = useState<ItemPendingFulfillmentStruct[]>([]);
  const [sortProps, setSortProps] = useState<SortProperties>({ field: 'date', order: 'DESC' });

  const [lotIdToChange, setLotIdToChange] = useState<string | null>();
  const [itemToAssemblyWorkorderCreation, setItemToAssemblyWorkorderCreation] =
    useState<ItemPendingFulfillmentStruct>();
  const [packageGroupToEditLots, setPackageGroupToEditLots] = useState<PackageGroup>();
  const [productItem, setProductItem] = useState<ItemPendingFulfillmentStruct>();
  const [tabValue, setTabValue] = useState(0);
  const [selectableFilters, setSelectableFilters] = useState<SelectableFiltersStruct>({});
  const [shipVia, setShipVia] = useState('ALL');
  const [itemType, setItemType] = useState('ALL');
  const [itemStatus, setItemStatus] = useState('ALL');

  const [statuses, setStatuses] = useState<any>({
    [PackageGroupFulfillmentStatus.PENDING]: 0,
    [PackageGroupFulfillmentStatus.PROCESSING]: 0,
    [PackageGroupFulfillmentStatus.FULFILLED]: 0,
  });

  const handleTabChange = (event: SyntheticEvent, value: number) => {
    setTabValue(value);
    setPageMode(value == 0 ? PageModes.ALL_DELIVERY_ORDERS : PageModes.ITEMS_PENDING_FULFILLMENT);
  };

  const handleFulfillmentOperation = (op: string, params: any) => {
    if (op == 'lot-batch-change') {
      setProductItem(params.product);
      setLotIdToChange(params.lotId);
    } else if (op == 'edit-package-group-fulfillment-lot') {
      setProductItem(params.product);
      setPackageGroupToEditLots(params.packageGroup);
    } else if (op == 'create-assembly-workorder') {
      setItemToAssemblyWorkorderCreation(params.item);
    }
  };

  function handleChangeDefaultLotClose(): void {
    setLotIdToChange(undefined);
    fetchItemsPendinfFulfillment();
  }

  function handleEditPackageGroupLotsClose(): void {
    setPackageGroupToEditLots(undefined);
    fetchItemsPendinfFulfillment();
  }

  function handleCreateAssemblyWorkorderClose(): void {
    setItemToAssemblyWorkorderCreation(undefined);
  }

  const handleSortClick = (field: string) => {
    if (sortProps.field === field) {
      setSortProps({ ...sortProps, order: sortProps.order === 'ASC' ? 'DESC' : 'ASC' });
    } else {
      setSortProps({ ...sortProps, field });
    }
  };

  const handleDeliveryDelete = () => {
    fetchDeliveryItems();
  };

  const fetchDeliveryItems = () => {
    auth && auth.account && auth.account.shipper?.timezone && moment.tz.setDefault(auth.account.shipper.timezone);
    const filters = fixDeliveryOrderFilters({ shipVia, shippingDate, orderDate });
    if (auth.account?.shipper?.shipperId) {
      return deliveryOrdersService
        .fetchCustomersOrders(
          auth.account?.shipper?.shipperId,
          filters.shippingDate,
          false,
          filters.shipVia,
          true,
          filters.orderDate,
        )
        .then((orders) => {
          const newStatuses: any = {};

          setAllDeliveryItems(
            orders?.map((order) => {
              order.packageGroups.forEach((pg) => {
                const pgStatus = pg.fulfillment?.status;
                if (pgStatus) {
                  newStatuses[pgStatus] = (newStatuses[pgStatus] ?? 0) + 1;
                }
              });
              const status =
                PackageGroupFulfillmentReadableStatus[getfulfillmentStatusOfPackageGroups(order.packageGroups ?? [])];

              return { ...order, status: `${status}` };
            }) ?? [],
          );
          setStatuses({ ...newStatuses });
        });
    }
  };

  const fetchItemsPendinfFulfillment = () => {
    auth && auth.account && auth.account.shipper?.timezone && moment.tz.setDefault(auth.account.shipper.timezone);
    if (auth.account?.shipper?.shipperId)
      return FulfillmentService.fetchItemsPendingFulfillment(
        fixDeliveryOrderFilters({ shipVia, shippingDate, orderDate, itemType }),
      ).then((items: ItemPendingFulfillmentStruct[]) => {
        setAllItemsPendingFulfillment(items);
      });
  };
  const refetchData = async () => {
    setNeedsRefresh(false);
    if (!loading) {
      setLoading(true);
      Promise.all([fetchDeliveryItems(), fetchItemsPendinfFulfillment()])
        .then(() => {
          setCurrentPage(1);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    setNeedsRefresh(true);
  }, [loggedIn, auth.account]);

  useEffect(() => {
    if (needsRefresh && !loading) {
      refetchData();
    }
  }, [needsRefresh]);

  useEffect(() => {
    if (currentPage <= 1 || allDeliveryItems.length / PAGE_ITEMS <= 1) setCurrentPage(1);
  }, [allDeliveryItems]);

  useEffect(() => {
    const itemStatuses: [string, string | null][] = [
      ['All', 'ALL'],
      [
        PackageGroupFulfillmentReadableStatus.INSUFFICIENT_INVENTORY,
        PackageGroupFulfillmentStatus.INSUFFICIENT_INVENTORY,
      ],
      [PackageGroupFulfillmentReadableStatus.PENDING, PackageGroupFulfillmentStatus.PENDING],
      [PackageGroupFulfillmentReadableStatus.PROCESSING, PackageGroupFulfillmentStatus.PROCESSING],
      [PackageGroupFulfillmentReadableStatus.PARTIAL_FULFILLED, PackageGroupFulfillmentStatus.PARTIAL_FULFILLED],
      [PackageGroupFulfillmentReadableStatus.FULFILLED, PackageGroupFulfillmentStatus.FULFILLED],
    ];

    const itemTypes: [string, string | null][] = [['All', 'ALL']];
    allItemsPendingFulfillment.forEach((item) => {
      if (!itemTypes.find((it) => it[1] == item.product.type)) {
        itemTypes.push([item.product.type, item.product.type]);
      }
    });
    const shipVias: [string, string | null][] = [
      ['All', 'ALL'],
      ['Curbhub', 'DEFAULT'],
    ];
    allDeliveryItems.map((order) => {
      if (order.shipVia && order.shipVia !== 'DEFAULT' && !shipVias.find((sv) => sv[1] == order.shipVia))
        shipVias.push([order.shipVia, order.shipVia]);
    });
    setSelectableFilters({ ...selectableFilters, shipVia: shipVias, itemType: itemTypes, itemStatus: itemStatuses });
  }, [allItemsPendingFulfillment, allDeliveryItems]);

  const handlePageClick = (page: number) => setCurrentPage(page);

  const handleFilterChange = (filters: any) => {
    if (filters.shipVia != shipVia) {
      setShipVia(filters.shipVia);
    }
    if (filters.itemType != itemType) {
      setItemType(filters.itemType);
    }
    if (filters.itemStatus != itemStatus) {
      setItemStatus(filters.itemStatus);
    }
    setShippingDate(filters.shippingDate || filters.orderDate ? filters.shippingDate : moment().format('YYYY-MM-DD'));
    setOrderDate(filters.orderDate);
    setNeedsRefresh(true);
  };

  const getSelectableFiltersOfPage = () => {
    const filters: any = { ...selectableFilters };
    if (pageMode == PageModes.ALL_DELIVERY_ORDERS) {
      delete filters.itemType;
      delete filters.itemStatus;
    }
    return filters;
  };

  let deliveryOrdersList = null;
  let itemsPendingFulfillmentList = null;

  if (pageMode === PageModes.ALL_DELIVERY_ORDERS) {
    if (allDeliveryItems) {
      let deliveryOrdersToShow: CustomerLocationOrder[] = allDeliveryItems;
      if (searchText !== '') {
        deliveryOrdersToShow = deliveryOrdersToShow.filter((deliveryOrder: CustomerLocationOrder) => {
          return getDeliveryOrderSearchString(deliveryOrder).toLowerCase().includes(searchText.toLowerCase());
        });
      }

      deliveryOrdersList = (
        <>
          <DeliveryOrdersAccordion
            title=""
            deliveryOrders={deliveryOrdersToShow
              ?.sort((a, b) => deliveriesSortFunction(sortProps.field, sortProps.order ?? 'DESC', a, b))
              .slice((currentPage - 1) * PAGE_ITEMS, currentPage * PAGE_ITEMS)}
            timezone={auth.account?.shipper?.timezone}
            onSortClick={handleSortClick}
            onDeliveriesDelete={handleDeliveryDelete}
            sortProps={sortProps}
            onError={(message) => {
              if (message) setToast(message, 'danger');
            }}
          />
          <Pagination
            totalRecords={deliveryOrdersToShow?.length}
            pageLimit={PAGE_ITEMS}
            pageNeighbours={10}
            onPageChanged={handlePageClick}
          />
        </>
      );
    } else {
      deliveryOrdersList = <div>No data to show</div>;
    }
  } else if (pageMode === PageModes.ITEMS_PENDING_FULFILLMENT) {
    if (allItemsPendingFulfillment) {
      itemsPendingFulfillmentList = (
        <ItemsPendingFulfillmentAccordion
          items={allItemsPendingFulfillment}
          visibleStatuses={itemStatus && itemStatus != 'ALL' ? [itemStatus] : undefined}
          visibleTypes={itemType && itemType != 'ALL' ? [itemType] : undefined}
          title=""
          onOperation={handleFulfillmentOperation}
        />
      );
    } else {
      itemsPendingFulfillmentList = <div>No data to show</div>;
    }
  }

  return (
    <Fragment>
      <Grid container spacing={1} margin={1}>
        <Grid item>
          <InfoCard
            caption="Pending"
            number={statuses[PackageGroupFulfillmentStatus.PENDING]}
            icon={<AccessTime />}
            loading={loading}
          />
        </Grid>
        <Grid item>
          <InfoCard
            caption="Processing"
            number={statuses[PackageGroupFulfillmentStatus.PROCESSING]}
            icon={<BlurCircular />}
            loading={loading}
          />
        </Grid>
        <Grid item>
          <InfoCard
            caption="Partialy Fulfilled"
            number={statuses[PackageGroupFulfillmentStatus.PARTIAL_FULFILLED]}
            icon={<Brightness6 />}
            loading={loading}
          />
        </Grid>
        <Grid item>
          <InfoCard
            caption="Fulfilled"
            number={statuses[PackageGroupFulfillmentStatus.FULFILLED]}
            icon={<Brightness5 />}
            loading={loading}
          />
        </Grid>
      </Grid>

      <SearchBox
        query={''}
        onQueryChange={setSearchText}
        disabled={loading}
        selectableFilters={getSelectableFiltersOfPage()}
        dateFilters={{ orderDate, shippingDate }}
        onFilterApplying={handleFilterChange}
        captions={{
          shippingDate: 'Shipping Date',
          orderDate: 'Order Date',
          shipVia: 'Ship Via',
          itemType: 'Item Type',
          itemStatus: 'Item Status',
        }}
      />
      <Card>
        <CardHeader color="primary">
          <Grid container p={0} m={0} alignItems="center">
            <Grid item flexGrow={1}>
              <span>Fulfillment</span>
            </Grid>
            <Grid item alignItems="center">
              <IconButton
                size="small"
                onClick={() => {
                  if (!loading) setNeedsRefresh(true);
                }}
              >
                {loading ? (
                  <CircularProgress size={24} style={{ color: 'white' }} />
                ) : (
                  <Refresh style={{ color: 'white' }} />
                )}
              </IconButton>
            </Grid>
          </Grid>
        </CardHeader>
        <CardBody>
          <Grid item>
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label="All Delivery Orders" />
              <Tab label="Items Pending Fulfillment" />
            </Tabs>
          </Grid>

          {pageMode === PageModes.ALL_DELIVERY_ORDERS && deliveryOrdersList}
          {pageMode === PageModes.ITEMS_PENDING_FULFILLMENT && itemsPendingFulfillmentList}
        </CardBody>
        <ChangeLotDialog
          lotIdToChange={lotIdToChange}
          onClose={handleChangeDefaultLotClose}
          itemPendingFulfillment={productItem}
        />
        <PackageGroupLotsDialog
          onClose={handleEditPackageGroupLotsClose}
          itemPendingFulfillment={productItem}
          packageGroup={packageGroupToEditLots}
        />
        <CreateAssemblyWorkorderDialog
          defaultCount={1}
          itemPendingFulfillment={itemToAssemblyWorkorderCreation}
          onClose={handleCreateAssemblyWorkorderClose}
          onNotify={setToast}
        />
      </Card>
    </Fragment>
  );
}

export default connector(Fulfillment);

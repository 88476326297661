import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
// import Card from '../../components/Card/Card';
// import CardBody from '../../components/Card/CardBody';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import {
  Customer,
  LatLng,
  RootState,
  TripArrangeStop,
  TripArrangeStopPackage,
  TripWithStopsDetails,
} from '../../store/config/types';
import { fetchCouriers } from '../../store/actions/couriers.actions';
import { couriersService } from '../../services/couriers.service';
import tripsService from '../../services/trips.service';
// import TripDnd from '../../components/TripsDnd/TripsDnd';
import { useHistory, useParams } from 'react-router-dom';
import { fetchTrips } from '../../store/actions/trips.actions';
import TripDndTimeline from '../../components/TripsDnd/TripDndTimeline';
import { DeliveryServiceProvider } from '../../store/config/types/deliveryServiceProvider.types';
import * as TripChangeHandler from './TripChangeHandlers';
import { TripsChangeStructure, TripsChangeType } from './TripChangeHandlers';
import { CircularProgress, Grid, IconButton } from '@mui/material';
import { Warehouse } from '../../store/config/types/warehouses.types';
import { InteractionTypeEnum } from '../../store/config/enums/interaction.enum';
import { ThirdPartyDeliveryConfirm } from '../../components/ThirdPartyDeliveryDialog/ThirdPartyDeliveryDialogs';
import { Cancel, Save } from '@material-ui/icons';
import Map from '../../components/Map/Map';

// import TripRow from '../../components/TripsDnd/TripRow';

interface EditTripsParams {
  date: string;
}

const mapStateToProps = (state: RootState) => {
  return {
    couriers: state.couriers,
    trips: state.trips,
    loggedIn: state.auth.loggedIn,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  fetchCouriers: () => dispatch(fetchCouriers()),
  fetchTrips: (from: Date | null = null, to: Date | null = null) => dispatch(fetchTrips(from, to)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function TripEdit({ fetchCouriers, fetchTrips, couriers, loggedIn, auth }: PropsFromRedux) {
  if (auth.account?.shipper?.timezone) moment.tz.setDefault(auth.account?.shipper?.timezone);

  const { date } = useParams<EditTripsParams>();
  const history = useHistory();

  const [availableCouriers, setAvailableCouriers] = useState<number[]>([]);
  const [deliveryServiceProviders, setDeliveryServiceProviders] = useState<DeliveryServiceProvider[]>([]);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
  // const [searchText, setSearchText] = useState<string>('');
  const [arrangedTrips, setArrangedTrips] = useState<TripWithStopsDetails[] | undefined>(undefined);
  const [unAssignStops, setUnAssignStops] = useState<TripArrangeStop[]>([]);
  const [originalUnAssignStops, setoriginalUnAssignStops] = useState<TripArrangeStop[]>([]);
  const [originalTrips, setOriginalTrips] = useState<TripWithStopsDetails[]>([]);
  const [saving, setSaving] = useState(false);
  const [optimizing, setOptimizing] = useState(false);
  const [thirdPartyConfirmation, setThirdPartyConfirmation] = useState(false);
  const [mapBoundPoints, setMapBoundPoints] = useState<LatLng[]>([]);
  const [selectedStopId, setSelectedStopId] = useState<number>();
  const [routing, setRouting] = useState(false);

  const getWarehouseOfStop = (stop: TripArrangeStop): TripArrangeStop | undefined => {
    if (stop && stop.stopPackages) {
      const warehouseId =
        stop.warehouseId ??
        (originalTrips
          ? originalTrips[stop.tripIndex].warehouseId ?? originalTrips[stop.tripIndex].tripStops[0].placeInfo?.placeId
          : 0);
      const warehouse = warehouses.find((warehouse) => warehouse.warehouseId === warehouseId) as any;
      const timelineStart = moment
        .max(
          arrangedTrips?.length
            ? arrangedTrips?.reduce((minDate, trip) => {
                const arrival = moment(trip.tripStops[0].schedulerArrivalAt ?? trip.tripStops[0].routerArrivalAt);
                return minDate < arrival ? minDate : arrival;
              }, moment(`${date} 23:59:59`, 'YYYY-MM-DD HH:mm'))
            : moment(`${date} 08:00:00`, 'YYYY-MM-DD HH:mm'),
          moment(),
        )
        .toDate();

      if (warehouse) {
        const warehouseArrivalTime = moment(timelineStart, 'YYYY-MM-DD HH:mm');

        return {
          label: 'W',
          name: warehouse.name,
          locationId: warehouse.locationId,
          location: warehouse.location,
          routerArrivalAt: warehouseArrivalTime.toDate(),
          routerDepartureAt: warehouseArrivalTime.add(5, 'minutes').toDate(),
          placeInfo: { placeId: warehouse.warehouseId, placeType: 'WAREHOUSE' },
          stopIndex: 0,
          tripIndex: stop.tripIndex,
          tripStopId: 0,
        } as TripArrangeStop;
      }
    }
    return undefined;
  };
  const fetchDeliveryServiceProviders = () => {
    setDeliveryServiceProviders([]);
    if (auth?.account?.shipper?.shipperId) {
      couriersService.fetchDeliveryServiceProviders().then((dsps) => setDeliveryServiceProviders(dsps ?? []));
    }
  };
  const startSaving = () => {
    if (saving) return;
    setSaving(true);
    setThirdPartyConfirmation(true);
  };
  const isDifferentTrip = (trip1: TripWithStopsDetails | undefined, trip2: TripWithStopsDetails | undefined) => {
    return (
      !(trip1 && trip2) ||
      trip1.isRoundTrip !== trip2.isRoundTrip ||
      !moment(trip1.startsAt).isSame(trip1.startsAt, 'minute') ||
      trip1.tripStops?.length !== trip2.tripStops.length ||
      trip1.tripStops?.some((stop1, stopIndex) => {
        const stop2 = trip2.tripStops[stopIndex];
        return (
          stop1.tripStopId !== stop2.tripStopId ||
          !moment(stop1.routerArrivalAt).isSame(stop2.routerArrivalAt, 'second')
        );
      })
    );
  };
  const removeFailedThirdPartyAndSave = (trips: TripWithStopsDetails[]) => {
    setSaving(true);
    const changeChain: TripsChangeStructure[] = [];
    trips
      .filter((trip) => trip.editable)
      .forEach((trip) => {
        if (trip.dspStatus !== 'SUBMITTED') {
          const tripToDelete = arrangedTrips?.find((aTrip) => aTrip.dspRefrenceId === trip.dspRefrenceId);
          if (tripToDelete !== undefined) {
            changeChain.push({
              changeType: TripChangeHandler.TripsChangeType.REMOVE_TRIP,
              sourceTripId: tripToDelete?.tripId,
            });
          }
        }
      });
    const chainChangeResult = TripChangeHandler.handleChangeChain(
      JSON.parse(JSON.stringify(arrangedTrips)),
      JSON.parse(JSON.stringify(unAssignStops)),
      changeChain,
      getWarehouseOfStop,
    );

    if (chainChangeResult.ok) {
      saveTrips(chainChangeResult.trips ?? [], chainChangeResult.unAssignStops ?? []);
    }
  };

  const saveTrips = (tripsToSave: TripWithStopsDetails[], unAssignStopsToSave: TripArrangeStop[]) => {
    const unassignedStopsIds = unAssignStopsToSave
      .filter((stop) => (stop.tripStopId ?? 0) > 0)
      .map((stop) => stop.tripStopId ?? 0);
    const deletedTripsIds = originalTrips
      .filter(
        (oTriop) =>
          (oTriop.tripId ?? -1) > 0 &&
          tripsToSave &&
          tripsToSave?.findIndex((aTrip) => (aTrip.tripId ?? -1) === (oTriop.tripId ?? 0)) < 0,
      )
      .map((trip) => trip.tripId ?? 0);
    const edittedTrips = tripsToSave
      ? tripsToSave
          .filter((filteredTrip) => {
            const oTrip = originalTrips?.find((trip) => trip.tripId === filteredTrip.tripId);
            return filteredTrip.tripStops?.length > 1 && isDifferentTrip(oTrip, filteredTrip);
          })
          .map((trip) => {
            trip.tripStops[0].stopPackages = trip.tripStops
              .filter((stop, stopIndex) => stopIndex > 0)
              .reduce((allPackage, stop) => {
                return [
                  ...allPackage,
                  ...(stop.stopPackages ?? []).map(
                    (stopPackage) =>
                      ({
                        ...stopPackage,
                        interactionType: InteractionTypeEnum.LOAD,
                        tripStopId: stop.tripStopId,
                      } as TripArrangeStopPackage),
                  ),
                ];
              }, [] as TripArrangeStopPackage[]);
            return {
              ...trip,
              tripStops: trip.tripStops.map((stop, stopIndex) => ({
                ...stop,
                routePoints:
                  stopIndex === 0
                    ? []
                    : tripsService.LocationsPathService.getPointsOfLocations([
                        trip.tripStops[stopIndex - 1].location,
                        trip.tripStops[stopIndex].location,
                      ]),
              })),
            };
          })
      : [];
    // console.log(edittedTrips, deletedTripsIds, unassignedStopsIds);

    tripsService.updateArrangedTrip(edittedTrips, deletedTripsIds, unassignedStopsIds).then((result) => {
      if (result)
        fetchTrips(moment(date, 'YYYY-MM-DD').toDate(), moment(date, 'YYYY-MM-DD').toDate()).finally(() => {
          history.goBack();
        });
    });
  };

  const handleCancel = () => {
    setSaving(true);
    tripsService
      .cancelEditTrip()
      .then((result) => {
        if (result) history.goBack();
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const handleChange = (changeStructs: TripsChangeStructure[]): boolean => {
    const chainChangeResult = TripChangeHandler.handleChangeChain(
      JSON.parse(JSON.stringify(arrangedTrips)),
      JSON.parse(JSON.stringify(unAssignStops)),
      changeStructs,
      getWarehouseOfStop,
    );

    if (chainChangeResult.ok) {
      setUnAssignStops(chainChangeResult.unAssignStops ?? []);
      setArrangedTrips((chainChangeResult.trips ?? []).filter((trip) => trip.tripStops.length > 1));
      setOptimizing(true);
      tripsService
        .optimizeTrips((chainChangeResult.trips ?? []).filter((trip) => trip.tripStops.length > 1))
        .then((trips) => {
          setArrangedTrips(trips);
          // TODO: here is suitable for route manipulate
        })
        .finally(() => {
          setOptimizing(false);
        });
    }
    return chainChangeResult.ok;
  };

  useEffect(() => {
    fetchDeliveryServiceProviders();
  }, []);

  useEffect(() => {
    if (!couriers.loadingCouriers && !couriers.couriers && !couriers.couriersErrorMessage) {
      fetchCouriers();
    }
  }, [fetchCouriers, couriers.couriers, couriers.couriersErrorMessage, couriers.loadingCouriers, loggedIn]);

  useEffect(() => {
    if (date) {
      tripsService
        .fetchTripsOfDateForEdit({ date: moment(date).toDate() })
        .then((result) => {
          if (result?.customers) setCustomers(result.customers);
          if (result?.warehouses) setWarehouses(result.warehouses);
          const trips = result?.trips;
          if (trips) {
            const unAssignedStops = trips.filter((trip) => trip.tripId === -1);
            setOriginalTrips(trips);
            setoriginalUnAssignStops(
              unAssignedStops.length > 0 ? JSON.parse(JSON.stringify(unAssignedStops[0].tripStops)) : [],
            );
            setUnAssignStops(unAssignedStops.length > 0 ? unAssignedStops[0].tripStops : []);
            setArrangedTrips(JSON.parse(JSON.stringify(trips?.filter((trip) => trip.tripId !== -1))));
          }
          if (trips?.length) {
            const allPointsOfVisibleTrips: LatLng[] = [];
            trips.forEach((trip) => {
              trip.tripStops?.forEach((stop) => {
                allPointsOfVisibleTrips.push({ lat: stop.location.latitude, lng: stop.location.longitude });
              });
            });
            setMapBoundPoints(allPointsOfVisibleTrips);
          }
        })
        .catch((e) => console.error(e));

      couriersService.fetchCouriersByDate(date).then((couriers) => {
        if (couriers) {
          setAvailableCouriers(couriers.map((c) => c.courierId ?? 0));
        }
      });
    }
  }, [date]);

  function optimizeArrangedTrips() {
    if (arrangedTrips) {
      setOptimizing(true);
      tripsService
        .optimizeTrips(arrangedTrips)
        .then((trips) => setArrangedTrips(trips))
        .finally(() => {
          setOptimizing(false);
        });
    }
  }
  function doZoneRouting() {
    if (unAssignStops?.length) {
      setOptimizing(true);
      setRouting(true);
      const locationIds: number[] = unAssignStops.map((stop) => stop.locationId ?? 0);
      tripsService.getDeliveryZones(locationIds).then((zones) => {
        let uStops: TripArrangeStop[] = JSON.parse(JSON.stringify(unAssignStops));
        const newTrips: TripWithStopsDetails[] = [];
        zones.forEach((zone) => {
          if (zone.locationIds.length) {
            // find unassign indexes
            const sourceStopPointers: TripChangeHandler.TripStopPointer[] = [];
            zone.locationIds.forEach((loc) => {
              const stop = uStops.find((stop) => stop.locationId === loc);
              if (stop) {
                const aPackageId =
                  stop.stopPackages && stop.stopPackages.length > 0 ? stop.stopPackages[0].packageId : 0;
                sourceStopPointers.push({ aPackageId });
              }
            });
            // create new trip
            const changeRes = TripChangeHandler.handleChangeChain(
              [],
              uStops,
              [
                {
                  changeType: TripsChangeType.ADD_NEW_TRIP,
                  sourceStopPointers,
                },
              ],
              getWarehouseOfStop,
            );
            if (changeRes.ok) {
              changeRes.trips?.forEach((trip) => {
                trip.isRoundTrip = true;
                trip.zoneCaption = zone.caption;
                trip.deliveryZoneId = zone.deliveryZoneId;
              });
              uStops = [...(changeRes.unAssignStops ?? [])];
              newTrips.push(...(changeRes.trips ?? []));
            }
          }
        });

        tripsService
          .optimizeTrips(newTrips, true)
          .then((trips) => {
            setArrangedTrips([...(arrangedTrips ?? []), ...trips]);
            setUnAssignStops(uStops);
          })
          .finally(() => {
            setOptimizing(false);
            setRouting(false);
          });
      });
    }
  }

  // const searchTextHandler = (text: string) => setSearchText(text);

  return (
    <div className="trips-dnd-full-page">
      {(arrangedTrips || unAssignStops) && (
        <>
          <div className="trips-dnd">
            <div className="trips-dnd-title-area">
              <Grid container>
                {!saving && (
                  <Grid flexGrow={1} textAlign="right">
                    <IconButton
                      size="small"
                      onClick={() => {
                        startSaving();
                      }}
                      disabled={saving || optimizing}
                    >
                      <Save style={{ color: saving ? 'gray' : 'white' }} />
                    </IconButton>

                    <IconButton
                      size="small"
                      onClick={() => {
                        handleCancel();
                      }}
                      disabled={saving}
                    >
                      <Cancel style={{ color: 'white' }} />
                    </IconButton>
                  </Grid>
                )}
                {saving && (
                  <Grid flexGrow={1} textAlign="right">
                    <IconButton size="small">
                      <CircularProgress size={24} />
                    </IconButton>
                    <IconButton size="small">
                      <CircularProgress size={24} />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </div>
            <div className="map-area">
              <Map
                trips={arrangedTrips ?? []}
                warehouses={warehouses}
                customersLocations={unAssignStops.map((stop) => stop.location)}
                boundPoints={mapBoundPoints}
                highlightStopId={selectedStopId}
                onStopClick={(stopId) => {
                  setSelectedStopId(stopId);
                }}
              />
            </div>
            <div className="chart-area">
              <TripDndTimeline
                trips={arrangedTrips ?? []}
                unAssignStops={unAssignStops}
                customers={customers}
                warehouses={warehouses}
                couriers={couriers.couriers ?? []}
                availableCouriersIds={availableCouriers}
                deliveryServiceProviders={deliveryServiceProviders}
                onReset={() => {
                  setArrangedTrips(JSON.parse(JSON.stringify(originalTrips?.filter((trip) => trip.tripId !== -1))));
                  setUnAssignStops(JSON.parse(JSON.stringify(originalUnAssignStops)));
                }}
                onBeforeChange={handleChange}
                onOptimizeLockChange={(tripIndex, value) => {
                  const trips: TripWithStopsDetails[] = JSON.parse(JSON.stringify(arrangedTrips));
                  trips[tripIndex].doNotOptimize = value;
                  setArrangedTrips(trips);
                }}
                onOptimizeClick={optimizeArrangedTrips}
                onZoneRoutingClick={doZoneRouting}
                routing={routing}
                showCurrentTime={true}
                timezone={auth.account?.shipper?.timezone}
                disableDnd={false}
                optimizing={optimizing}
                editMode={true}
              />
            </div>
          </div>
        </>
      )}

      <ThirdPartyDeliveryConfirm
        open={thirdPartyConfirmation}
        trips={arrangedTrips?.filter((trip) => (trip.editable ?? true) && trip.DSP)}
        customers={customers}
        onClose={() => {
          setThirdPartyConfirmation(false);
        }}
        onDeliveryCreationComplete={removeFailedThirdPartyAndSave}
      />
    </div>
  );
}

export default connector(TripEdit);

import { Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { AssemblyBuild, AssemblyBuildItem } from '../../store/config/types';
import { AssemblyBuildStatus, AssemblyBuildStatusReadableStatus } from '../../store/config/enums/warehouseWork.enum';
import { FormatDateByTimezone } from '../../utils/string.helpers';
import { useState } from 'react';
import { KeyboardArrowDown, KeyboardArrowUp, Publish } from '@material-ui/icons';

export interface AssemblyBuildWorkorderTableRowProps {
  assemblyBuild: AssemblyBuild;
  timezone?: string;
  key: string;
}
export function AssemblyBuildWorkorderTableRow({ assemblyBuild, timezone, key }: AssemblyBuildWorkorderTableRowProps) {
  const [isExpanded, setExpanded] = useState(false);
  const progress = { count: 0, proceed: 0 };
  let assemblyCount = 0;
  const assemblyBuildItems: AssemblyBuildItem[] = [];
  assemblyBuild.warehouseWorkOrders.forEach((wo) => {
    assemblyCount += wo.ratio ?? 0;
    progress.count += wo.assemblyBuildItems?.length ?? 0;
    wo.assemblyBuildItems.forEach((abItem) => {
      if (abItem.builtQuantity > 0) progress.proceed += 1;
      const iIndex = assemblyBuildItems.findIndex((i) => i.productId == abItem.productId);
      if (iIndex < 0) {
        assemblyBuildItems.push(abItem);
      } else {
        assemblyBuildItems[iIndex].quantity += abItem.quantity;
      }
    });
  });
  return (
    <>
      <TableRow
        key={key}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setExpanded(!isExpanded);
        }}
      >
        <TableCell style={isExpanded ? { borderBottom: 'none' } : {}}>{assemblyBuild.assemblyBuildId}</TableCell>
        <TableCell style={isExpanded ? { borderBottom: 'none' } : {}}>
          {FormatDateByTimezone(assemblyBuild.creationDate, timezone)}
        </TableCell>
        <TableCell style={isExpanded ? { borderBottom: 'none' } : {}}>{assemblyBuild.product?.name}</TableCell>
        <TableCell style={isExpanded ? { borderBottom: 'none' } : {}}>{assemblyBuild.product?.description}</TableCell>
        <TableCell style={isExpanded ? { borderBottom: 'none' } : {}}>{assemblyCount}</TableCell>
        <TableCell style={isExpanded ? { borderBottom: 'none' } : {}}>
          {progress.count > 0 ? Math.round((progress.proceed * 100) / progress.count).toFixed(0) : 0}%
        </TableCell>
        <TableCell style={isExpanded ? { borderBottom: 'none' } : {}}>
          {AssemblyBuildStatusReadableStatus[assemblyBuild.status]}
        </TableCell>
        <TableCell style={isExpanded ? { borderBottom: 'none' } : {}} align="right">
          {assemblyBuild.status != AssemblyBuildStatus.BUILT && !assemblyBuild.synced && (
            <IconButton>
              <Publish />
            </IconButton>
          )}
          <IconButton
            onClick={() => {
              setExpanded(!isExpanded);
            }}
          >
            {isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
      </TableRow>
      {isExpanded && (
        <TableRow>
          <TableCell colSpan={7}>
            <Grid container p={1}>
              <Typography variant="button">Items</Typography>
              <Table>
                <TableHead>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Quantity</TableCell>
                </TableHead>
                <TableBody>
                  {assemblyBuildItems.map((item, index) => {
                    return (
                      <TableRow key={`assembly-item-${index}`}>
                        <TableCell>{item.product?.externalId}</TableCell>
                        <TableCell>{item.product?.name}</TableCell>
                        <TableCell>{item.quantity}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Grid>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

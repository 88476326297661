/* eslint-disable */
export enum WarehouseWorkReferenceType {
  PACKAGE_GROUP = 'PACKAGE_GROUP',
  ASSEMBLY = 'ASSEMBLY',
}

export enum WarehouseWorkType {
  PICK = 'PICK',
  PACK = 'PACK',
  TRANSFER = 'TRANSFER',
}

export enum PackageGroupFulfillmentStatus {
  INSUFFICIENT_INVENTORY = 'INSUFFICIENT_INVENTORY',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  PARTIAL_FULFILLED = 'PARTIAL_FULFILLED',
  FULFILLED = 'FULFILLED',
  MIXED_STATUS = 'MIXED_STATUS',
}

export enum PackageGroupFulfillmentReadableStatus {
  INSUFFICIENT_INVENTORY = 'Insufficient Inventory',
  PENDING = 'Pending Fulfillment',
  PROCESSING = 'Processing',
  PARTIAL_FULFILLED = 'Partially Fulfilled',
  FULFILLED = 'Fulfilled',
  MIXED_STATUS = 'Mixed Status',
}

export enum AssemblyBuildStatus {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  // PARTIAL_BUILT = 'PARTIAL_BUILT',
  BUILT = 'BUILT',
  INSUFFICIENT_INVENTORY = 'INSUFFICIENT_INVENTORY',
}

export enum AssemblyBuildStatusReadableStatus {
  PENDING = 'Pending Build',
  PROCESSING = 'Processing',
  // PARTIAL_BUILT = 'Partially Built',
  BUILT = 'Built',
  INSUFFICIENT_INVENTORY = 'Insufficient Inventory',
}

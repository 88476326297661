import {
  AssemblyBuild,
  FulfillmentItem,
  ItemPendingFulfillmentStruct,
} from '../store/config/types/warehouseWork.types';
import axios from './axios';

function filterToQuery(filters: any) {
  let query: string = '';
  Object.keys(filters)
    .filter((filterName) => filters[filterName])
    .forEach((filterName, index) => {
      query += `${index == 0 ? '?' : '&'}${filterName}=${filters[filterName]}`;
    });
  return encodeURI(query);
}

async function fetchItemsPendingFulfillment(filters: any): Promise<ItemPendingFulfillmentStruct[]> {
  const res = await axios.get(encodeURI(`/warehouse-work/items-pending-fulfillment${filterToQuery(filters)}`));
  return res.data.data as ItemPendingFulfillmentStruct[];
}

async function fetchAssembliesPendingBuild(filters: any): Promise<ItemPendingFulfillmentStruct[]> {
  const res = await axios.get(encodeURI(`/warehouse-work/assemblies-pending-build${filterToQuery(filters)}`));
  return res.data.data as ItemPendingFulfillmentStruct[];
}

async function changeDefaultLot(allWorkOrderIds: number[], productId: string | number, lotId: string) {
  const res = await axios.patch(encodeURI(`/warehouse-work/default-lot`), { allWorkOrderIds, productId, lotId });
  return res.data.data;
}

async function updatePackageGroupLots(packageGroupFulfillmentId: number, fulfillmentItems?: FulfillmentItem[]) {
  const res = await axios.patch(encodeURI(`/warehouse-work/fulfillment-items`), {
    packageGroupFulfillmentId,
    fulfillmentItems,
  });
  return res.data.data;
}

async function fetchAssemblyBuildWorkorders(filters: any): Promise<AssemblyBuild[]> {
  const res = await axios.get(encodeURI(`/warehouse-work/assembly-build-workorders${filterToQuery(filters)}`));
  return res.data.data as AssemblyBuild[];
}

async function createAssemblyWorkorder(productId: number, assemblyCount: number) {
  const res = await axios.post(encodeURI(`/warehouse-work/assembly-build-workorders`), { productId, assemblyCount });
  return res.data.ok;
}

async function changeLotOfAssemblyItem(assemblyBuildItemIds: number[], lotId: string) {
  const res = await axios.patch(encodeURI(`/warehouse-work/assembly-build-item`), {
    assemblyBuildItemIds,
    lotId,
  });
  return res.data.ok;
}

export const FulfillmentService = {
  fetchItemsPendingFulfillment,
  changeDefaultLot,
  updatePackageGroupLots,
};

export const AssemblyBuildService = {
  fetchAssemblyBuildWorkorders,
  fetchAssembliesPendingBuild,
  createAssemblyWorkorder,
  changeLotOfAssemblyItem,
};

const WarehouseWorkService = {
  ...FulfillmentService,
  ...AssemblyBuildService,
};
export default WarehouseWorkService;

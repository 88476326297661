import { Grid, Link, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { AssemblyItemPendingProductionStruct } from '../../store/config/types';
import { fixNumber } from '../../utils/string.helpers';

export interface BuildOrdersOfAssemblyItemProps {
  assemblyItem: AssemblyItemPendingProductionStruct;
  // eslint-disable-next-line
  onAction?: (actionName: string, params: any) => void;
}
export default function BuildOrdersOfAssemblyItem({ assemblyItem, onAction }: BuildOrdersOfAssemblyItemProps) {
  const changeLotOfAssemblyBuildItem = (buildItem: any) => {
    if (onAction) onAction('change-lot-of-assembly-build-item', buildItem);
  };
  const isLotNumbredItem = assemblyItem.product?.type.toLowerCase().includes('lot');
  let uom = assemblyItem.product.unitOfMeasure;
  if (uom == 'Each') uom = '';
  const info = {
    ordered: 0,
    built: 0,
  };
  return (
    <Table style={{ marginBottom: 32 }}>
      <TableHead>
        <TableCell>ID</TableCell>
        <TableCell>Assembly</TableCell>
        {isLotNumbredItem && <TableCell>LotID:Qty</TableCell>}

        <TableCell align="center">Ordered</TableCell>
        <TableCell align="center">Built</TableCell>
        <TableCell></TableCell>
      </TableHead>
      <TableBody>
        {assemblyItem.assemblyBuilds.map((assemblyBuild, index) => {
          return (
            <TableRow key={`assembly-build-${index}`}>
              <TableCell>{assemblyBuild.assemblyBuildId}</TableCell>
              <TableCell>{assemblyBuild.product.name}</TableCell>
              {isLotNumbredItem && (
                <TableCell>
                  {assemblyBuild.warehouseWorkOrders.map((wo) => {
                    const buildItem = wo.assemblyBuildItems.find((i) => i.productId == assemblyItem.product.productId);
                    if (!buildItem) return <></>;
                    info.ordered += buildItem.quantity;
                    info.built += buildItem.builtQuantity;
                    return (
                      <Grid container key={`lotQty-${buildItem.lotId}`} whiteSpace="nowrap">
                        <Link
                          onClick={() => {
                            changeLotOfAssemblyBuildItem(buildItem);
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          {`${buildItem.lotId ?? 'Unknown'}`}{' '}
                          <span style={{ display: 'inline-block', textDecoration: 'none', color: 'black' }}>
                            {' '}
                            :{`${fixNumber(buildItem.quantity)}`}
                          </span>
                        </Link>
                      </Grid>
                    );
                  })}
                </TableCell>
              )}

              <TableCell align="center">
                {info.ordered} {uom}
              </TableCell>
              <TableCell align="center">
                {info.built} {uom}
              </TableCell>
              <TableCell>
                {/* <IconButton>
                  <Publish />
                </IconButton> */}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

import { Table, TableBody, TableCell, TableHead } from '@mui/material';
import { AssemblyBuild } from '../../store/config/types';
import { AssemblyBuildWorkorderTableRow } from './AssemblyBuildWorkorderTableRow';

export interface AssemblyBuildWorkorderTableProps {
  assemblyBuilds: AssemblyBuild[];
  timezone?: string;
}
export default function AssemblyBuildWorkorderTable({ assemblyBuilds, timezone }: AssemblyBuildWorkorderTableProps) {
  return (
    <Table>
      <TableHead>
        <TableCell>ID</TableCell>
        <TableCell>Creation Date</TableCell>
        <TableCell>Assembly</TableCell>
        <TableCell>Description</TableCell>
        <TableCell>Count</TableCell>
        <TableCell>Progress</TableCell>
        <TableCell>status</TableCell>
        <TableCell>Actions</TableCell>
      </TableHead>
      <TableBody>
        {assemblyBuilds.map((assemblyBuild, index) => {
          return (
            <AssemblyBuildWorkorderTableRow
              assemblyBuild={assemblyBuild}
              key={`assembly-build-${index}`}
              timezone={timezone}
            />
          );
        })}
      </TableBody>
    </Table>
  );
}

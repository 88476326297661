import { PackageGroupFulfillmentStatus, WarehouseWorkType } from '../store/config/enums/warehouseWork.enum';
import { PackageGroup } from '../store/config/types';

export function getfulfillmentSummaryOfPackageGroup(pg: PackageGroup) {
  const summary = {
    commited: 0,
    ordered: 0,
    fulfilled: 0,
    status: PackageGroupFulfillmentStatus.PENDING,
    someFulfilled: false,
    somePending: false,
    somePartialFulfilled: false,
    someProcessing: false,
    lotQty: [] as { lotId: string | null; quantity: number }[],
  };

  pg.fulfillment?.warehouseWorkOrders?.forEach((wo) => {
    if (wo.workType == WarehouseWorkType.PICK) {
      wo.fulfillmentItems?.forEach((ffi) => {
        summary.ordered += ffi.quantity;
        if (ffi.lotId) summary.commited += ffi.quantity;
        summary.fulfilled += ffi.fulfilledQuantity;
        summary.somePending = summary.somePending || ffi.fulfilledQuantity === 0;
        summary.someFulfilled = summary.someFulfilled || ffi.fulfilledQuantity >= ffi.quantity;
        summary.somePartialFulfilled =
          summary.somePartialFulfilled || (ffi.fulfilledQuantity > 0 && ffi.fulfilledQuantity < ffi.quantity);
        const lotIdx = summary.lotQty.findIndex((lq) => lq.lotId === ffi.lotId);
        if (lotIdx < 0) {
          summary.lotQty.push({ lotId: ffi.lotId, quantity: ffi.quantity });
        } else {
          summary.lotQty[lotIdx].quantity += ffi.quantity;
        }
      });
    }
  });
  if (summary.commited < summary.ordered) {
    summary.status = PackageGroupFulfillmentStatus.INSUFFICIENT_INVENTORY;
  } else if (summary.somePending) {
    if (summary.someFulfilled || summary.somePartialFulfilled) {
      summary.status = PackageGroupFulfillmentStatus.PROCESSING;
    } else {
      summary.status = PackageGroupFulfillmentStatus.PENDING;
    }
  } else {
    if (summary.somePartialFulfilled) {
      summary.status = PackageGroupFulfillmentStatus.PARTIAL_FULFILLED;
    } else {
      summary.status = PackageGroupFulfillmentStatus.FULFILLED;
    }
  }

  return {
    commited: summary.commited,
    ordered: summary.ordered,
    fulfilled: summary.fulfilled,
    status: summary.status,
    lotQty: [...summary.lotQty],
  };
}

export function getfulfillmentStatusOfPackageGroups(packageGroups: PackageGroup[]) {
  let somePending = false;
  let someProcessing = false;
  let someFulfilled = false;
  let somePartialFulfilled = false;
  let status = PackageGroupFulfillmentStatus.PROCESSING;

  packageGroups.forEach((pg) => {
    switch (pg.fulfillment?.status) {
      case PackageGroupFulfillmentStatus.PENDING:
        somePending = true;
        break;
      case PackageGroupFulfillmentStatus.PROCESSING:
        someProcessing = true;
        break;
      case PackageGroupFulfillmentStatus.PARTIAL_FULFILLED:
        somePartialFulfilled = true;
        break;
      case PackageGroupFulfillmentStatus.FULFILLED:
        someFulfilled = true;
        break;
    }
  });

  if (somePending) {
    if (someFulfilled || somePartialFulfilled || someProcessing) {
      status = PackageGroupFulfillmentStatus.PROCESSING;
    } else {
      status = PackageGroupFulfillmentStatus.PENDING;
    }
  } else {
    if (someProcessing) {
      status = PackageGroupFulfillmentStatus.PROCESSING;
    } else if (somePartialFulfilled) {
      status = PackageGroupFulfillmentStatus.PARTIAL_FULFILLED;
    } else {
      status = PackageGroupFulfillmentStatus.FULFILLED;
    }
  }
  return status;
}

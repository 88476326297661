import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';
import { Warning } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './style.scss';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import moment from 'moment-timezone';
import DeliveryOrderActions from './DeliveryOrderActions';
import {
  getConclusionOfStatuses,
  getPackageGroupLabel,
  getStatusOfAllPackageGroups,
  isOutsideTheTimeWindow,
  readablePackageStatus,
} from '../../utils/deliveryOrders.helpers';
import { calculateDeliveryExpectedTime, formatDeliveryOrderTimeWindow } from '../../utils/string.helpers';
import { CustomerLocationOrder, OrderItem } from '../../store/config/types';
import { SyntheticEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';

export interface SingleDeliveryAccordionProps {
  deliveryOrder: CustomerLocationOrder;
  index: number;
  timezone?: string;
  /* eslint-disable */
  setDeliveryToAction: (DeliveryOrder: CustomerLocationOrder) => void;
  setOpenDeleteDialog: (value: boolean) => void;
  setOpenPODView: (value: boolean) => void;
  /* eslint-enable */
}

export default function SingleDeliveryAccordion({
  deliveryOrder,
  index,
  timezone,
  setDeliveryToAction,
  setOpenDeleteDialog,
  setOpenPODView,
}: SingleDeliveryAccordionProps) {
  const [tabPage, setTabPage] = useState(0);
  const history = useHistory();

  const changeTab = (event: SyntheticEvent, newValue: number) => {
    setTabPage(newValue);
  };

  const handleMoreMenuClick = (deliveryOrder: CustomerLocationOrder, menuAction: string, id?: number) => {
    setDeliveryToAction(deliveryOrder);
    switch (menuAction) {
      case 'delete':
        setOpenDeleteDialog(true);
        break;
      case 'pod':
        setOpenPODView(true);
        break;
      case 'edit':
        history.push('/delivery-orders/package-group/' + id);
        break;
      default:
        alert(`UNknown command: ${menuAction}${id ? ':' + id : ''}`);
    }
  };
  const getPackages = (deliveryOrder: CustomerLocationOrder) => {
    const rows: any[][] = [];
    deliveryOrder.packageGroups.forEach((packageGroup) => {
      packageGroup.packages.forEach((pkg, index) => {
        rows.push([
          `${pkg.packageId}`,
          pkg.size,
          <span key={`status-${index}`} className={`package_status ${pkg.changeHandStatus}`}>
            {readablePackageStatus(pkg.changeHandStatus)}
          </span>,
          <span key={`comment-${index}`} className={`package_comment ${pkg.changeHandStatus}`}>
            {pkg.statusComment ?? ''}
          </span>,
        ]);
      });
    });
    return rows;
  };

  let statusClass = getStatusOfAllPackageGroups(deliveryOrder, true);
  let deliveryOrderSatus = deliveryOrder.status;
  const missTimeWindow = isOutsideTheTimeWindow(deliveryOrder, timezone);
  if (missTimeWindow) {
    statusClass = 'NEEDS_ATTENTION';
    deliveryOrderSatus = getConclusionOfStatuses(['MISS_TIME_WINDOW']);
  }

  return (
    <Accordion
      className={`delivery-order-accordion ${statusClass}`}
      key={`delivery-order-${index}`}
      // onChange={handleChange(index)}
    >
      <AccordionSummary className="doa-head" expandIcon={<ExpandMoreIcon />} aria-controls="">
        <DeliveryOrderActions deliveryOrder={deliveryOrder} onMenuSelect={handleMoreMenuClick} />
        <Typography className="doa-label">
          {deliveryOrder.packageGroups.map((packageGroup) => {
            const idLabel = getPackageGroupLabel(packageGroup);
            const idLink = packageGroup.additionalData?.externalLink ? (
              <Link href={packageGroup.additionalData?.externalLink} target="_blank">
                {idLabel}
              </Link>
            ) : (
              <span>{idLabel}</span>
            );
            return <span key={`id-${packageGroup.packageGroupId}`}>{idLink}</span>;
          })}
        </Typography>
        <Typography className="doa-company">{deliveryOrder.customer.companyName}</Typography>
        <Typography className="doa-name">{`${deliveryOrder.customer.firstName} ${deliveryOrder.customer.lastName}`}</Typography>
        <Typography className={`doa-addressee`}>{`${deliveryOrder.shipToAddressee ?? ''}`}</Typography>
        <Typography className={`doa-address`}>{`${deliveryOrder.shippingLocation?.streetAddress}`}</Typography>
        <Typography className="doa-packages-count">{`${deliveryOrder.packageGroups.reduce((sum, packageGroup) => {
          return sum + packageGroup.packages.length;
        }, 0)}`}</Typography>

        <Typography className="doa-time">{calculateDeliveryExpectedTime(deliveryOrder, timezone) ?? ''}</Typography>
        <Typography className="doa-shipVia">
          {deliveryOrder.shipVia == 'DEFAULT' ? 'Curbhub' : deliveryOrder.shipVia}
        </Typography>

        <Typography className="doa-status">{`${deliveryOrderSatus}`}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {missTimeWindow && (
          <GridContainer>
            <GridItem xs={12}>
              <Typography color="error" className="doa-time-window-alert ">
                <Warning style={{ marginRight: 8 }} />
                {deliveryOrder.tripId &&
                  `${
                    deliveryOrder.packageGroups[0]?.startDelayMinutes ?? 0 > 0 ? ' Due to late start, ' : ''
                  }Delivery Time (${moment(deliveryOrder.packageGroups[0].departure).format(
                    'hh:mm a',
                  )}) is outside the `}
                Requested Time Window ({formatDeliveryOrderTimeWindow(deliveryOrder, true)})
              </Typography>
            </GridItem>
          </GridContainer>
        )}
        <GridContainer>
          <GridItem xs={12}>
            <Tabs value={tabPage} onChange={changeTab}>
              <Tab label="Packages" value={0} />
              <Tab label="Items" value={1} />
            </Tabs>
            {tabPage === 0 && (
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Size</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getPackages(deliveryOrder).map((packageItem, index) => (
                    <TableRow key={`row-${index}`}>
                      <TableCell>{packageItem[1]}</TableCell>
                      <TableCell>{packageItem[2]}</TableCell>
                      <TableCell>{packageItem[3]}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
            {tabPage === 1 && (
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Source</TableCell>
                    <TableCell>ID</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Quantity</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {deliveryOrder.packageGroups
                    .reduce((allOrderItems, packageGroup) => {
                      return allOrderItems.concat(packageGroup.orderItems ?? []);
                    }, [] as OrderItem[])
                    .map((item, itemIndex) => (
                      <TableRow key={`item-${itemIndex}`}>
                        <TableCell>{item.externalSource}</TableCell>
                        <TableCell>{item.externalId ?? item.itemId}</TableCell>
                        <TableCell>{item.description}</TableCell>
                        <TableCell>{item.quantity}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            )}
          </GridItem>
        </GridContainer>
      </AccordionDetails>
    </Accordion>
  );
}

import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { useEffect, useState } from 'react';
import {
  Courier,
  LatLng,
  StopLocation,
  Trip,
  TripParking,
  TripStopLabelStruct,
  TripWithStopsDetails,
} from '../../store/config/types';
import { Warehouse } from '../../store/config/types/warehouses.types';
import StopMarker from './StopMarker';
import CustomerMarker from './CustomerMarker';
import RoutePath from './RoutePath';
import TripService from '../../services/trips.service';
import CourierMarker from './CourierMarker';
import { IconButton } from '@mui/material';
import { AspectRatio } from '@material-ui/icons';
import './style.scss';
import { ColorPalette } from '../../store/config/types/ColorSpectrum';
import { getStopSequence } from '../TripsDnd/helper';
import ParkingMarker from './ParkingMarker';

// import { stopSortBySchedulerTimes, stopSortByComputedTimes } from '../../utils/trips.helper';

const MAX_MAP_ZOOM = 14;
interface MapProps {
  trips?: Trip[] | TripWithStopsDetails[];
  labels?: TripStopLabelStruct[];
  warehouses?: Warehouse[];
  defaultCenter?: LatLng;
  visibleTripsIds?: number[];
  customersLocations?: StopLocation[];
  routePoints?: LatLng[];
  couriers?: Courier[];
  isTripHighLight?: boolean;
  highlightStopId?: number;
  highlightCustomerId?: number;
  highlightedCourierId?: number;
  locationsDistanceType?: string;
  boundPoints?: LatLng[];
  isLoading?: boolean;
  parkings?: TripParking[];
  /* eslint-disable */
  onStopHover?: (stopId: number, isOver: boolean) => void;
  onCustomerHover?: (customerId: number, isOver: boolean) => void;
  onMapClick?: () => void;
  onStopClick?: (stopId: number) => void;
  onCourierClick?: (courierId: number) => void;
  /* eslint-enable */
}

const Map = ({
  trips,
  labels,
  warehouses,
  visibleTripsIds,
  customersLocations,
  couriers,
  highlightStopId,
  highlightCustomerId,
  highlightedCourierId,
  locationsDistanceType = 'router',
  boundPoints,
  isLoading,
  onMapClick,
  onStopClick,
  onCourierClick,
}: MapProps) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GEOCODING_API_KEY ?? '',
    mapIds: ['e4db26c48942ca86'],
  });
  //   const google: any = window.google;
  const [mapRef, setMapRef] = useState<any>(null);
  const [infoHash, setInfoHash] = useState<string>();
  const [zoomLevel, setZoomLevel] = useState('all');

  const onMapLoad = (gmap: any) => {
    setMapRef(gmap);
  };
  const reBound = (forcedZoomLevel?: string): void => {
    const bounds = new window.google.maps.LatLngBounds();
    if (!forcedZoomLevel && boundPoints && boundPoints.length) {
      if (boundPoints.length === 1) {
        setZoomLevel('location');
        console.log(`zoom to point`);
      } else {
        setZoomLevel('collection');
      }
      boundPoints.forEach((bPoint) => {
        bounds.extend(bPoint);
      });
    } else if (trips) {
      const selectedTripIndex = highlightStopId
        ? trips.findIndex((trip) => {
            return trip.tripStops.findIndex((stop) => highlightStopId === stop.tripStopId) >= 0;
          })
        : -2;
      // console.log(`zoom to trip ${selectedTripIndex}`);
      if (forcedZoomLevel === 'collection' && selectedTripIndex >= 0) {
        const selectedTrip = trips[selectedTripIndex];
        setZoomLevel('collection');
        selectedTrip.tripStops.forEach((stop) => {
          bounds.extend({ lat: stop.location?.latitude, lng: stop.location?.longitude });
        });
      } else {
        setZoomLevel('all');
        let anyVisibleTrips = false;
        if (mapRef) {
          if (trips && trips.length) {
            trips.forEach((trip) => {
              const tripIsVisible = !visibleTripsIds || visibleTripsIds.includes(trip.tripId ?? 0);
              anyVisibleTrips ||= tripIsVisible;
              tripIsVisible &&
                (trip.tripStops ?? []).forEach((stop) => {
                  bounds.extend({ lat: stop.location?.latitude, lng: stop.location.longitude });
                });
            });
          }
          if (customersLocations && customersLocations.length) {
            customersLocations.forEach((location) => {
              bounds.extend({ lat: location?.latitude, lng: location.longitude });
            });
            anyVisibleTrips = true;
          }
          if (!anyVisibleTrips && warehouses && warehouses.length) {
            warehouses.forEach((warehouse) => {
              bounds.extend({ lat: warehouse.location?.latitude, lng: warehouse.location.longitude });
            });
          }
        }
      }
    }

    mapRef?.fitBounds(bounds);
    if (mapRef?.getZoom() > MAX_MAP_ZOOM) {
      mapRef?.setZoom(MAX_MAP_ZOOM);
    }
    // alert(mapRef?.getZoom());
  };
  const centerTo = (center: LatLng) => {
    if (mapRef) {
      mapRef?.panTo(center);
      window.google.maps.event.addListenerOnce(mapRef, 'idle', function () {
        if (center) {
          mapRef?.setCenter(center);
        }
      });
    }
  };
  const handleBoundChange = () => {};
  const handleReboundBtnClicked = () => {
    let zLevel = 'all';
    if (zoomLevel === 'location') {
      zLevel = 'collection';
    }
    reBound(zLevel);
  };

  const getPathLocation = (locationQueue: StopLocation[]) => {
    if (locationQueue.length > 1) {
      const thisLocation = locationQueue[1] as StopLocation;
      const prevLocation = locationQueue.shift() as StopLocation;
      return [prevLocation, thisLocation];
    }
    return [] as StopLocation[];
  };
  useEffect(() => {
    reBound();
  }, [mapRef, boundPoints]);
  useEffect(() => {
    if (isLoading) return;
    let highlightstopIndex = 0;
    const tripIndex = trips?.findIndex((trip) => {
      return (
        trip.tripStops.findIndex((stop, stopIndex) => {
          const found = highlightStopId === stop.tripStopId;
          if (found) highlightstopIndex = stopIndex;
          return found;
        }) >= 0
      );
    });
    if (highlightstopIndex && trips) {
      const location = trips[tripIndex ?? 0].tripStops[highlightstopIndex].location;
      centerTo({ lat: location.latitude, lng: location.longitude });
    }
  }, [highlightStopId, isLoading]);
  useEffect(() => {
    if (isLoading) return;
    const newHash =
      'T:' +
      visibleTripsIds?.sort().join('|') +
      'C' +
      (customersLocations?.map((loc) => loc?.locationId ?? 0) ?? []).sort().join('|');
    if (newHash !== infoHash) {
      reBound('all');
      setInfoHash(newHash);
    }
  }, [visibleTripsIds, customersLocations, isLoading]);

  let anyVisibleTrips = false;

  return !isLoaded ? (
    <h1>Loading...</h1>
  ) : (
    <GoogleMap
      options={{ streetViewControl: false, zoomControl: true, mapId: 'e4db26c48942ca86', disableDefaultUI: true }}
      mapContainerClassName="map-area-google-map"
      onLoad={onMapLoad}
      onClick={onMapClick}
      onBoundsChanged={handleBoundChange}
      // onZoomChanged={() => {
      //   setStopRebound(true);
      // }}
    >
      <div className="map-rebound-btn">
        <IconButton
          sx={{ borderRadius: 0 }}
          onClick={() => {
            handleReboundBtnClicked();
          }}
        >
          <AspectRatio />
        </IconButton>
      </div>

      {trips &&
        trips.map((trip, tripIndex) => {
          const displayComputed = locationsDistanceType !== 'router';

          const tripParkings: TripParking[] = displayComputed
            ? TripService.LocationsPathService.getTripParkings(trip.tripId ?? 0)
            : [];

          const stopSequence = getStopSequence(trip.tripStops, displayComputed);

          const tripIsVisible = !visibleTripsIds || visibleTripsIds.includes(trip.tripId ?? 0);
          const tripColor = ColorPalette[tripIndex % ColorPalette.length];
          anyVisibleTrips ||= tripIsVisible;
          const locationQueue: StopLocation[] = [];

          const returnPathLocations: StopLocation[] = [];

          return tripIsVisible ? (
            <>
              {stopSequence.map((stopInfo, sequenceIndex) => {
                const stopIndex = trip.tripStops.findIndex((tripStop) =>
                  tripStop.tripStopId
                    ? tripStop.tripStopId === stopInfo.tripStopId
                    : stopInfo.tripStopId === tripStop.locationId,
                );
                const capturedStop = trip.tripStops[stopIndex];

                if (stopIndex >= 0) {
                  locationQueue.push(capturedStop.location);
                  if (trip.isRoundTrip && (sequenceIndex === 0 || sequenceIndex === stopSequence.length - 1)) {
                    returnPathLocations.unshift(capturedStop.location);
                  }
                }

                const pathLocations = getPathLocation(locationQueue);
                // if (capturedStop?.tripStopId === highlightStopId) console.log(pathLocations);
                return (
                  <RoutePath
                    key={`route-${stopIndex}`}
                    color={tripColor}
                    points={TripService.LocationsPathService.getPointsOfLocations(pathLocations, locationsDistanceType)}
                    highlight={capturedStop?.tripStopId === highlightStopId}
                  />
                );
              })}
              {trip.isRoundTrip && displayComputed && (
                <RoutePath
                  key={`route-return`}
                  color={tripColor}
                  points={TripService.LocationsPathService.getPointsOfLocations(
                    returnPathLocations,
                    locationsDistanceType,
                  )}
                  isReturn
                />
              )}
              {trip.tripStops.map((tripStop, tripStopIndex) => {
                const capturedStopIndex = stopSequence.findIndex((s: any) => s.tripStopId === tripStop.tripStopId);
                const label = labels?.find(
                  (lblStruct) => lblStruct.tripId === trip.tripId && lblStruct.tripStopId === tripStop.tripStopId,
                )?.label;

                return (
                  <StopMarker
                    key={`stop-${tripStop.tripStopId}`}
                    trip={trip}
                    stop={tripStop}
                    label={label}
                    stopIndex={tripStopIndex}
                    color={capturedStopIndex >= 0 ? tripColor : { ...tripColor, dark40: 'gray', light20: 'lightgray' }}
                    highlight={tripStop.tripStopId === highlightStopId}
                    onClick={onStopClick}
                    displayComputed={displayComputed}
                    // onHover={onStopHover}
                  />
                );
              })}
              {tripParkings &&
                tripParkings.map((parking, parkingIndex) => {
                  return <ParkingMarker key={`parking-${parkingIndex}`} parking={parking} />;
                })}
            </>
          ) : (
            <></>
          );
        })}
      {!anyVisibleTrips &&
        (customersLocations ?? []).length === 0 &&
        warehouses &&
        warehouses.map((warehouse, warehouseIndex) => {
          return (
            <Marker
              key={`w-${warehouseIndex}`}
              position={{ lat: warehouse.location.latitude, lng: warehouse.location.longitude }}
              label={'W'}
            />
          );
        })}
      {(customersLocations ?? []).map((loc, locIndex) => {
        return (
          <div key={`customer-${locIndex}`} className="map-pin">
            <CustomerMarker
              highlight={highlightCustomerId === loc.customer?.customerId}
              // onHover={onCustomerHover}
              location={loc}
              color={'gray'}
              customerIndex={locIndex}
            />
          </div>
        );
      })}
      {(couriers ?? []).map((courier) => {
        const tripIndex = trips?.findIndex((trip) => trip.courierId === courier.courierId) ?? -1;
        return (
          <CourierMarker
            key={`courier-${courier.courierId}`}
            courier={courier}
            color={tripIndex >= 0 ? ColorPalette[tripIndex % ColorPalette.length] : undefined}
            courierLastLocation={courier.locationHistory ? courier.locationHistory[0] : undefined}
            highlighted={highlightedCourierId === courier.courierId}
            onCourierClick={onCourierClick}
          />
        );
      })}
    </GoogleMap>
  );
};

export default Map;

import { Table, TableBody, TableCell, TableHead } from '@mui/material';
import { AssemblyBuild, AssemblyItemPendingProductionStruct } from '../../store/config/types';
import moment from 'moment-timezone';
import AssemblyPendingProductionTableRow from './AssemblyPendingProductionTableRow';

export interface AssemblyPendingProductionTableProps {
  assemblyBuilds: AssemblyBuild[];
  timezone?: string;
  // eslint-disable-next-line
  onAction?: (actionName: string, params: any) => void;
}
export default function AssemblyPendingProductionTable({
  assemblyBuilds,
  timezone,
  onAction,
}: AssemblyPendingProductionTableProps) {
  if (timezone) moment.tz.setDefault(timezone);
  const assemblyItems: AssemblyItemPendingProductionStruct[] = [];
  assemblyBuilds.forEach((assemblyBuild) => {
    const woCounts = assemblyBuild.warehouseWorkOrders?.length ?? 0;
    if (woCounts) {
      assemblyBuild.warehouseWorkOrders[0].assemblyBuildItems.forEach((item) => {
        const aIndex = assemblyItems.findIndex((a) => a.product.productId == item.productId);
        if (aIndex < 0) {
          assemblyItems.push({
            product: item.product,
            assemblyBuilds: [assemblyBuild],
            inventories: [],
          });
        } else {
          assemblyItems[aIndex].assemblyBuilds.push(assemblyBuild);
        }
      });
    }
  });
  return (
    <Table>
      <TableHead>
        <TableCell>Product ID</TableCell>
        <TableCell>Name</TableCell>
        <TableCell>Description</TableCell>
        <TableCell>Ordered</TableCell>
        <TableCell>Built</TableCell>

        <TableCell></TableCell>
      </TableHead>
      <TableBody>
        {assemblyItems.map((item, index) => (
          <AssemblyPendingProductionTableRow assemblyItem={item} key={`row-${index}`} onAction={onAction} />
        ))}
      </TableBody>
    </Table>
  );
}

import { useEffect, useState, Fragment, ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { History } from 'history';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import moment from 'moment-timezone';
import { RootState } from '../../store/config/types';
import { CustomerLocationOrder, SortProperties } from '../../store/config/types/deliveryOrders.types';
import { Warehouse } from '../../store/config/types/warehouses.types';
import { fetchCustomersDeliveryOrders } from '../../store/actions/deliveryOrders.actions';
import { fetchWarehouses } from '../../store/actions/warehouses.actions';
import AddButton from '../../components/CustomButtons/AddButton';
import DatePicker from '../../components/DatePicker/DatePicker';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import SpecialInput from '../../components/SpecialInput/Input';
import Pagination from '../../components/Pagination/Pagination';
import Spinner from '../../components/Spinner/Spinner';
//import TableList from '../../components/TableList/TableList';
import { formatDate } from '../../utils/string.helpers';
import DeliveryOrdersAccordion from '../../components/DeliveryOrdersAccordion/DeliveryOrdersAccordion';
import {
  deliveriesSortFunction,
  getDeliveryOrderSearchString,
  getStatusOfAllPackageGroups,
} from '../../utils/deliveryOrders.helpers';
import { hasPermission } from '../../utils/permissions';
import { setToast } from '../../store/actions/toast.actions';

const PAGE_LIMIT: number = 12;

const mapStateToProps = (state: RootState) => {
  return {
    auth: state.auth,
    customersDeliveryOrders: state.customersDeliveryOrders,
    warehouses: state.warehouses,
    loggedIn: state.auth.loggedIn,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  fetchCustomersDeliveryOrders: (shipperId: number, from: string, to: string) =>
    dispatch(fetchCustomersDeliveryOrders(shipperId, from, to)),
  fetchWarehouses: (shipperId: number) => dispatch(fetchWarehouses(shipperId)),
  setToast: (message: string, messageType: string) => dispatch(setToast(message, messageType)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function DeliveryOrders({
  auth,
  customersDeliveryOrders,
  warehouses,
  loggedIn,
  fetchCustomersDeliveryOrders,
  fetchWarehouses,
  setToast,
}: PropsFromRedux) {
  moment.tz.setDefault(auth.account?.shipper?.timezone);
  const history: History = useHistory();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchText, setSearchText] = useState<string>('');
  const [dateFrom, setDateFrom] = useState<string>(moment().startOf('week').format('YYYY-MM-DD'));
  const [dateTo, setDateTo] = useState<string>('');
  const [sortProps, setSortProps] = useState<SortProperties>({ field: 'date', order: 'DESC' });

  const handleSortClick = (field: string) => {
    if (sortProps.field === field) {
      setSortProps({ ...sortProps, order: sortProps.order === 'ASC' ? 'DESC' : 'ASC' });
    } else {
      setSortProps({ ...sortProps, field });
    }
  };
  const handleDeliveryDelete = (packageGroupIds?: number[]) => {
    if (packageGroupIds && packageGroupIds.length > 0) {
      fetchCustomersDeliveryOrders(auth.account?.shipper?.shipperId || 0, dateFrom, dateTo);
    }
  };
  useEffect(() => {
    if (
      !customersDeliveryOrders.loadingCustomersDeliveryOrders &&
      !customersDeliveryOrders.customersDeliveryOrders &&
      !customersDeliveryOrders.customerDeliveryOrdersErrorMessage
    ) {
      fetchCustomersDeliveryOrders(auth.account?.shipper?.shipperId || 0, dateFrom, dateTo);
    }
  }, [
    fetchCustomersDeliveryOrders,
    customersDeliveryOrders.customersDeliveryOrders,
    customersDeliveryOrders.customerDeliveryOrdersErrorMessage,
    customersDeliveryOrders.loadingCustomersDeliveryOrders,
    loggedIn,
    auth.account,
  ]);
  useEffect(() => {
    fetchCustomersDeliveryOrders(auth.account?.shipper?.shipperId || 0, dateFrom, dateTo);
  }, [dateFrom, dateTo]);

  useEffect(() => {
    if (
      !warehouses.loadingWarehouses &&
      !warehouses.warehouses &&
      !warehouses.warehousesFetched &&
      !warehouses.warehousesErrorMessage &&
      auth.account
    ) {
      fetchWarehouses(auth.account?.shipper?.shipperId || 0);
    }
  }, [
    fetchWarehouses,
    warehouses.loadingWarehouses,
    warehouses.warehouses,
    warehouses.warehousesFetched,
    warehouses.warehousesErrorMessage,
    auth.account,
  ]);

  const handlePageClick = (page: number) => setCurrentPage(page);

  const handleCreateDeliveryOrder = () => history.push('/delivery-orders/create');

  // const makeHashIndex = (order: CustomerOrders): string =>
  //   'C' + order.customerId + 'W' + order.warehouseId + order.deliveryExpectedAt;

  // const handleUpdateDeliveryOrder = (deliveryOrderId: string) =>
  //   history.push(`/delivery-orders/${deliveryOrderId}/detail`);

  // const ShowDeliveryOrderDetails = (hashedId: string) => {
  //   const customerOrder = customersDeliveryOrders.customersDeliveryOrders?.find(
  //     (order) => makeHashIndex(order) === hashedId,
  //   );
  //   if (customerOrder)
  //     history.push(
  //       `/delivery-orders/details/${customerOrder.warehouseId}/${customerOrder.customerId}/${customerOrder.deliveryExpectedAt}`,
  //     );
  // };

  const searchTextHandler = (text: string) => setSearchText(text);

  const handleDateRange = (e: ChangeEvent<HTMLInputElement>) =>
    e.target.name === 'Date from' ? setDateFrom(e.target.value) : setDateTo(e.target.value);

  const filters = (
    <GridContainer>
      <GridItem xs={12} md={4}>
        <SpecialInput
          element={{
            elementType: 'input',
            elementConfig: { type: 'text', placeholder: 'Search..' },
            value: searchText,
            validation: {},
          }}
          onChange={searchTextHandler}
        />
      </GridItem>
      <GridItem xs={12} md={3}>
        <DatePicker label="Date from" value={dateFrom} onChange={handleDateRange} />
      </GridItem>
      <GridItem xs={12} md={3}>
        <DatePicker label="Date until" value={dateTo} onChange={handleDateRange} />
      </GridItem>
    </GridContainer>
  );

  let deliveryOrdersList = null;

  if (customersDeliveryOrders.customersDeliveryOrders) {
    let deliveryOrdersToShow: CustomerLocationOrder[] = customersDeliveryOrders.customersDeliveryOrders || [];

    deliveryOrdersToShow = deliveryOrdersToShow.filter((deliveryOrder: CustomerLocationOrder) => {
      const fromDate = moment(dateFrom);
      const toDate = moment(dateTo);
      const deliveryOrderTime = moment(deliveryOrder.deliveryExpectedAt);

      if (dateFrom && dateTo && fromDate <= toDate) {
        return deliveryOrderTime.isBetween(fromDate, toDate, 'days', '[]');
      }
      return deliveryOrder !== null;
    });

    if (searchText !== '') {
      deliveryOrdersToShow = deliveryOrdersToShow.filter((deliveryOrder: CustomerLocationOrder) => {
        const warehouse = warehouses.warehouses?.find((w: Warehouse) => w.warehouseId === deliveryOrder.warehouseId);
        const searchString = getDeliveryOrderSearchString(deliveryOrder);

        let isMatch = searchString.toLowerCase().includes(searchText.toLowerCase());
        if (warehouse?.name?.toLowerCase().includes(searchText.toLowerCase())) {
          isMatch = true;
        }

        if (
          moment
            .utc(deliveryOrder.deliveryExpectedAt)
            .format('MM/DD/YYYY')
            .toLowerCase()
            .includes(searchText.toLowerCase())
        ) {
          isMatch = true;
        }

        if (
          deliveryOrder.deliveryExpectedAt &&
          formatDate(deliveryOrder.deliveryExpectedAt?.toString()).toLowerCase().includes(searchText.toLowerCase())
        ) {
          isMatch = true;
        }
        isMatch ||= ['packagesAmount', 'numberOfRecipients'].some((key) => {
          return (deliveryOrder as any)[key]?.toString().toLowerCase().includes(searchText.toLowerCase());
        });
        return isMatch;
      });
    }

    deliveryOrdersToShow = deliveryOrdersToShow.map((order) => {
      return { ...order, status: getStatusOfAllPackageGroups(order) };
    });
    deliveryOrdersList = (
      <>
        {filters}
        <DeliveryOrdersAccordion
          title="Deliveries"
          deliveryOrders={deliveryOrdersToShow
            ?.sort((a, b) => deliveriesSortFunction(sortProps.field, sortProps.order ?? 'DESC', a, b))
            .slice((currentPage - 1) * PAGE_LIMIT, currentPage * PAGE_LIMIT)}
          sortProps={sortProps}
          onSortClick={handleSortClick}
          onDeliveriesDelete={handleDeliveryDelete}
          onError={(message) => {
            if (message) setToast(message, 'danger');
          }}
        />
        <Pagination
          totalRecords={deliveryOrdersToShow?.length}
          pageLimit={PAGE_LIMIT}
          pageNeighbours={10}
          onPageChanged={handlePageClick}
        />
      </>
    );
  } else if (customersDeliveryOrders.loadingCustomersDeliveryOrders) {
    deliveryOrdersList = <Spinner />;
  } else {
    deliveryOrdersList = <div>No data to show</div>;
  }

  if (!customersDeliveryOrders.customersDeliveryOrdersFetched) {
    return <Spinner />;
  }

  return (
    <Fragment>
      {deliveryOrdersList}
      {auth.account && hasPermission(auth.account, ['shipperAdmin.deliveryOrders.create']) && (
        <AddButton onClick={handleCreateDeliveryOrder} />
      )}
    </Fragment>
  );
}

export default connector(DeliveryOrders);
